<template>
    <div class="col-12">
        <h2 class="mb-1">Modulo de Historial</h2>
        <div class="row">
            <div class="form-group col-md-3 col-11">
                <label for="formGroupExampleInput">Fecha inicial</label>
                <input v-model="fecha_inicio" type="date" id="fecha_inicio" class="form-control">
            </div>
            <div class="form-group col-md-3 col-11">
                <label for="formGroupExampleInput">Fecha final</label>
                <input v-model="fecha_final" type="date" id="theDate" class="form-control">
            </div>
            <div class="form-group col-md-3 col-11 my-auto">
                <button type="button" class="btn btn-primary" @click="actualizar" :disabled="!status">Consultar <span v-if="!status" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
            </div>
            <div class="form-group col-md-3 col-12 mt-4 d-none">
                <div class="input-group">
                    <input v-model="buscarNumero" @keypress.enter="verHistorial" type="text" class="form-control" placeholder="Numero de telefono a buscar" max="13" maxlength="13" aria-label="" aria-describedby="basic-addon1">
                    <div class="input-group-append">
                        <button @click="verHistorial" class="btn btn-info" type="button" :disabled='!status'><font-awesome-icon icon="search" /></button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <p class="text-center"><img src="https://images.vexels.com/media/users/3/153988/isolated/preview/948fa94b504782eef35dbfb511443868-mantenimiento-del-sitio-web-icono-de-trazo-de-color-by-vexels.png" alt=""></p> -->
        <conversaciones :fechaI="fecha_inicio" :fechaF="fecha_final" class=""/>
        <traspasos :fechaI="fecha_inicio" :fechaF="fecha_final" class="mt-3" />
        <!-- <finalizadas class="mt-3" /> -->
        
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faSearch)
import Ttraspasos from '@/components/Dashboard/Tablas/Traspasos.vue'
import TConversaciones from '@/components/Dashboard/Tablas/Conversaciones.vue'
// import TFinalizadas from '@/components/Dashboard/Tablas/Finalizados.vue'
export default {
    name: 'Historial',
    components: {
        'traspasos':Ttraspasos,
        'conversaciones':TConversaciones,
        // 'finalizadas': TFinalizadas,
        'font-awesome-icon': FontAwesomeIcon
    },
    data() {
        return {
            fecha_inicio: '',
            fecha_final: '',
            uno:1,
            status: false,
            numeroCelular:null,
            buscarNumero: null
        }
    },
    created() {
        this.$bus.$off('respuestaStatus')
        this.$bus.$on('respuestaStatus', status => {
            this.status=status
        })
        let today = new Date();
        this.maxDate = new Date();
        this.maxDate.setDate(today.getDate() - 1);
    },
    mounted() {
        var date = new Date()

        var day = date.getDate()
        var month = date.getMonth() + 1
        var year = date.getFullYear()

        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day

        var today = year + "-" + month + "-" + day
        this.fecha_final = today
        this.fecha_inicio = `${year}-${month}-${day}`
    },
    methods: {
        actualizar() {
            this.status=false
            this.$bus.$emit('updateHistorial', 1)
            
        },
        verHistorial(){
            this.status=false
        }
    }
}
</script>