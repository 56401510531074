<template>
    <div class="hrPicoResponsivo">
        <Chart type="line" :data="basicData" :options="options" class="mb-3 d-none d-sm-none d-md-block" />
        <Chart type="line" :data="basicData" :options="options" class="mb-3 d-block d-sm-block d-md-none  graficaResponsiva" />
    </div>
</template>
<script>
import Chart from 'primevue/chart';
// import reportesServices from '../../../services/reportes'
import SocketIo from 'socket.io-client'
export default {
    name: 'GMsjXhr',
    components: {
        'Chart': Chart
    },
    created() {
        this.cargardatos()
    },
    data() {
        return {
            basicData: null,
            options: {
            },
            hiloGrafica: null,
            horaspico: null,
            horaAsesor: null,
            horaEspera: null,
            fecha: 2020,
            
        }
    },
    methods: {
        cargardatos() {
             
            
        
            let data = {
                query: {
                    "Tipo": "1"
                }
            }

            var socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
            // console.log('Fuera NuevaGrafica')
            socket.on('NuevaGrafica',  (hr) => {
                //console.log('Dentro NuevaGrafica')
                // console.log('NuevaGrafica',hr)
                console.log(JSON.stringify(hr))
            // reportesServices.monitorHR('2020-05-22','').then(hr => {
                this.horaspico=hr
                let totalesP=[]
                let totalesC=[]
                let totalesA=[]
                let totalesB=[]
                let totalesBi=[]
                let totalesS=[]
                let horas=[]
                var moment = require('moment')
                moment.locale('es')
                this.options= {
                responsive: true,
                hoverMode: 'index',
                scales: {
                    yAxes: [{
                        type: 'linear',
                        display: true,
                        scaleLabel: {
							display: true,
                            labelString: 'Clientes',
                            fontSize: 18
						},
                        position: 'right',
                        id: 'der',
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: moment(hr[0].date).format('LL')
						}}],
                },
                legend: {
                    labels: {
                        fontSize: 14
                    },
                    
                }
            }
                    for (let x=0; x<this.horaspico.length; x++){                     
                        if(this.horaspico[x].hora>5 && this.horaspico[x].hora<22){
                            totalesP.push(this.horaspico[x].totalPacientes)
                            totalesC.push(this.horaspico[x].totalPacientesAtendidosMal)
                            totalesBi.push(this.horaspico[x].totalPacientesAtendidosBien)
                            totalesA.push(this.horaspico[x].totalAtendidos)
                            totalesB.push(this.horaspico[x].totalBot)
                            totalesS.push(this.horaspico[x].totalPacientesNoAtendidos)

                            if(this.horaspico[x].hora==0){
                                horas.push('12am')
                            } else if(this.horaspico[x].hora<12){
                                horas.push(this.horaspico[x].hora+'am')
                            }else if(this.horaspico[x].hora==12){
                                horas.push('12pm')
                            }else if(this.horaspico[x].hora>12){
                                horas.push((this.horaspico[x].hora-12)+'pm')
                            }
                        }
                    }

                    this.basicData = {
                        labels: horas,
                        datasets: [
                        {
                            label: 'Atención de hoy',
                            data: totalesBi,
                            fill: true,
                            backgroundColor: '#258e4dDD',
                            borderColor: '#258e4dDD',
                        },
                            {
                            label: 'No atendidos hoy',
                            data: totalesS,
                            fill: true,
                            borderDash: [3,  5],
                            backgroundColor: '#af000090',
                            borderColor: '#aa000090',
                        },
                        {
                            label: 'Atención anterior',
                            data: totalesC,
                            fill: true,
                            borderDash: [5, 5],
                            backgroundColor: '#FDE308c0',
                            borderColor: '#FDE30890',
                        },
                        
                        //     {
                        //     label: 'En asesor',
                        //     data: totalesA,
                        //     fill: true,
                        //     backgroundColor: '#339AF090',
                        //     borderColor: '#007BFF',
                        // },
                        //     {
                        // //     label: 'En Bot',
                        // //     data: totalesB,
                        // //     fill: true,
                        // //     backgroundColor: '#5D9E6A90',
                        // //     borderColor: 'green',
                        // // },{
                        //     label: 'Total',
                        //     data: totalesP,
                        //     fill: true,
                        //     backgroundColor: '#aaa',
                        //     borderColor: '#666',
                        //     //lineTension: 0.1
                        // },                  
                        ]
                }
            })
            
                
        
        },
        llenarGrafica(){
            
            
        },
        // pollData() {
        //     this.polling = setInterval(() => {
        //        this.cargardatos()
        //     }, 1800000)
            
         
        // }
    },
    destroyed() {
      this.cargardatos()
  }

}
</script>
