<template>
<DataTable :value="traspasos" ref="traspasos" :filters="filters" :paginator="true" :rows="8" class="p-datatable-responsive">
    <template #header>

        Traspasos de Clientes
        <div style="text-align: left">
            <i class="pi pi-search" style="margin: 4px 4px 0px 0px;"></i>
            <InputText v-model="filters['global']" placeholder="Filtro global" size="30" />
        </div>
        <div style="text-align: right">
            <Button class="btn btn-success top10" label="Export" @click="exportCSV($event)"><span class="d-none d-sm-none d-md-block">Exportar a Excel</span> <font-awesome-icon class="d-block d-sm-block d-md-none" icon="file-excel"/><i class="far fa-file-excel"></i></Button>
        </div>
    </template>
    <Column field="paciente_id" header="Cliente" :sortable="true"></Column>
    <Column field="asesorE" header="Asesor emisor" :sortable="true"></Column>
    <Column field="asesorR" header="Asesor receptor" :sortable="true"></Column>
    <Column field="motivo" header="Motivo de transferencia" :sortable="true"></Column>
    <Column field="fecha" header="Hora y fecha" :sortable="true"></Column>
</DataTable>
</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    library
} from "@fortawesome/fontawesome-svg-core"
import {
    faFileExcel
} from "@fortawesome/free-solid-svg-icons"
library.add(faFileExcel)
import reportesServices from '../../../services/reportes'
export default {
    name: 'Ttraspasos',
    components: {
        'DataTable': DataTable,
        'Column': Column,
        // 'MultiSelect': MultiSelect,
        'InputText': InputText,
        'font-awesome-icon': FontAwesomeIcon
    },
    props: {
        fechaI : null,
        fechaF : null,
    },
    data() {
        return {
            filters: {},
            traspasos:''
        }
    },
    created(){
    },
    mounted() {
        //this.carService.getCarsLarge().then(data => this.cars = data); 
        this.cargarTraspasos()
    },
    methods: {
        exportCSV() {
            this.$refs.traspasos.exportCSV();
        },
        cargarTraspasos() {
            reportesServices.getTraspasos().then(resp => {  
                var moment = require('moment')
                moment.locale('es')
                resp = resp.map(x =>{
                    x.fecha = moment(x.fecha).utc().format('YYYY MMMM DD, dddd, hh:mm:ss a');
                    return x
                })
                this.traspasos = resp

            })
        }
    }
}
</script>
