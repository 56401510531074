import api from './api'
import apiFB from './apiFB'
let reportes = {}

reportes.cargarPacientesAtendidos = function(opcion) {
    return api.get('/reporte/pacientesatendidos?opcion=' + opcion).then(res => res.data)
}

reportes.getPacientesAtendidos = function() {
    return api.get('/reporte/pacientesatendidosasesor').then(res => res.data)
}

reportes.getChats = function() {
    return api.get('/chats').then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            console.log('Token Expirado ', err)
        }
    })
}

reportes.reporte = function(fecha_inicial, fecha_final, rep) {
    let respuesta = ''
    switch (rep) {
        case 1:
            respuesta = api.get('/reporte/pacientespordia?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 2:
            respuesta = api.get('/reporte/pacientespordiaasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 3:
            respuesta = api.get('/reporte/detpacientespordiaasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
        case 4:
            respuesta = api.get('/reporte/motivostransferencias?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
            break
    }

    return respuesta

}
reportes.getHistorialChats = function(fecha_inicial, fecha_final) {
    return api.get(`/chatshistorico?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            // this.$gtag.event('tokenExpirado',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': '12hrs conectado',
            //     'value': 1
            // })
        }
    })
}

reportes.getTraspasos = function() {
    return api.get('/traspasos').then(res => res.data)
}

reportes.mensajesPacientes = function(fecha_inicial, fecha_final, rep) {
    return api.get('/reporte/mensajesusuario?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
}

reportes.mensjaesAsesores = function(fecha_inicial, fecha_final, rep) {
    return api.get('/reporte/mensajesasesor?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
}

reportes.pacientesAtendidos = function(fecha_inicial, fecha_final, rep) {
    return api.get('/reporte/pacientespordia?' + `fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&opcion=${rep}`).then(res => res.data)
}

reportes.felizometroReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/felizometro?' +`inicio=${fecha_inicial}&fin=${fecha_final}`).then(res => res.data)
}
reportes.felizometro = function() {
    return api.get('/reporte/felizometro').then(res => res.data)
}
reportes.horaPico = function() {
    return api.get('/reporte/horaspico').then(res => res.data)
}
reportes.monitorHR = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/monitorhoras?'+ `fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.horaAtencion = function() {
    return api.get('/reporte/horasatencion').then(res => res.data)
}
reportes.horaCola = function() {
    return api.get('/reporte/horassolicitudasesor').then(res => res.data)
}
reportes.horaPicoReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/horaspico?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.resultadoReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/resultados?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}&tipo=day`).then(res => res.data)
}
reportes.resultadoReporteSemanal = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/resultados?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}&tipo=week`).then(res => res.data)
}

reportes.flujos = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/flujos?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.horasAsesor = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/horasasesor?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.asesorAtendiendo = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/atendiendo?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.asesorSesion = function() {
    return api.get('/reporte/atendiendomonitor').then(res => res.data)
}

reportes.felizometroAsesorReporte = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/felizometroasesor?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.atencionPorAsesor = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/asesormensajes?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.covidIntents = function(fecha_inicial, fecha_final) {
    return api.get('/covid/intents?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            // this.$gtag.event('tokenExpirado',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': '12hrs conectado',
            //     'value': 1
            // })
        }
    })
}
reportes.tiemposMkt = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/tiemposmarketing/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.tiemposDia = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/tiemposdia/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.octubreIntents = function(fecha_inicial, fecha_final) {
    return api.get('/octubrerosa/intents?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            // this.$gtag.event('tokenExpirado',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': '12hrs conectado',
            //     'value': 1
            // })
        }
    })
}

reportes.covidChat = function(fecha_inicial, fecha_final) {
    return api.get('/covid/mensajes?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}

reportes.octubreChat = function(fecha_inicial, fecha_final) {
    return api.get('/octubrerosa/mensajes?' +`fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.resultadosPerdidosCovid = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/pacientesresultadosnows?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.pacientesNoAtendidos = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/pacientesnoatendidos/cola?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.sesionesPerdidas = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/lostsessions?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.atencionAVG = function(fecha_inicial, fecha_final) {
    return api.get('/reporte/atencion?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.bitacora = function(tipo, fecha_inicial, fecha_final) {
    return api.get(`/reporte/bitacora?tipo=${tipo}&fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.etiqueta = function( fecha_inicial, fecha_final) {
    return api.get(`/reporte/etiquetas?fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.facebookAtendidos = function( fecha_inicial, fecha_final) {
    return apiFB.get(`/reporte/atendidos?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.facebookAtendidosU = function( fecha_inicial, fecha_final) {
    return apiFB.get(`/reporte/atendidosunicos?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.facebookSolicitudAsesor = function( fecha_inicial, fecha_final) {
    return apiFB.get(`reporte/solicitudasesor?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.facebookPacientes = function( fecha_inicial, fecha_final) {
    return apiFB.get(`/reporte/pacientes?fecha_inicio=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
reportes.nicaraguaPxAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/nicaragua/reporte/pacientesasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.nicaraguaPxporAsesor = function( fecha_inicial, fecha_final) {
    return api.get(`/nicaragua/reporte/pacientescadaasesor/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
reportes.nicaraguaPacientes = function( fecha_inicial, fecha_final) {
    return api.get(`/nicaragua/reporte/pacientes/${fecha_inicial}/${fecha_final}`).then(res => res.data)
}
export default reportes