<template>
<div id="page-content-wrapper" :class="{msjDashboard: ruta != ('/chat')}">
    <div class="contChat">
        <div class="alert cambioOrigen text-center mt-4 mb-4 nombreConversacion" role="alert" v-if="mensajes.length!=0">
            <b v-if="network == 'FB'|| network == 'FBni'">{{ chat.nombre }}</b>
            <b v-else-if="network == 'WA'||'WAni'">{{ formatNumber(chat.numero) }}</b>
        </div>
        <div class="container-fluid mensajesContenedor" v-if="mensajes.length!=0">
            <ul class="chat" id="conversacion">
                <li v-for="(msj, index) in mensajes" :key="msj.id" :class="{chatDer: msj.origen == ('3'||'1'), chatIzq: msj.origen == '2'}">
                    <!-- Mensajes enviados -->
                    <div v-if="index==0 || msj.origen != mensajes[index-1].origen" class="nombreMsj" :class="msj.origen == '2' ? 'textIzq' : 'textDer'">
                        <span v-if="msj.origen == '1'">Bot</span>
                        <span v-if="msj.origen == '2'">{{ chat.nombre }}</span>
                        <span v-if="msj.origen == '3'">Tú</span>
                        <img v-if="msj.origen == '1'" src="../assets/images/imagen-usuario.svg" height="50" alt="" />
                        <img v-if="msj.origen == '2'" src="../assets/images/user.png" height="50" alt="" />
                        <img v-if="msj.origen == '3'" src="../assets/images/imagen-usuario.svg" height="50" alt="" />
                    </div>
                    <div v-if="(msj.origen == '3') || (msj.origen == '1')" class="msj-rta macro" :class="{'msj-rtaImg': msj.mensaje.tipo == '2','tipoBot': msj.origen == '1', 'msjImg': msj.mensaje.tipo == '7'}">
                        <div class="text text-r">
                            <small v-if="msj.asesor!='Tú'" v-show="msj.asesor_id!=0" cl>{{msj.asesor}} dijo:</small>
                            <p v-if="msj.mensaje.tipo == '1'" v-html="msj.mensaje.msj"></p>
                            <img v-else-if="msj.mensaje.tipo == '2'" class="card-img-top img300 imagenChat" :src="msj.mensaje.msj" alt="Imagen de respuesta" title="Enviada por usuario">
                            <b v-else-if="msj.mensaje.tipo == '3'">SE ENVIÓ PDF</b>
                            <audio v-else-if="msj.mensaje.tipo == '5'" :src="msj.mensaje.msj" type="audio/ogg" preload="none" controls></audio>
                            <div v-else-if="msj.mensaje.tipo == '6'" class="imgMapa"><a class="linkMapa" :href="'https://www.google.com/maps/search/?api=1&query='+msj.mensaje.msj+'&heading=-45&pitch=38&fov=80'" target="_blank"><img src="../assets/images/mapa.png"><b class="ubiClinica">Se envió ubicación</b></a></div>
                            <div v-else-if="msj.mensaje.tipo == '7'"><img @click="abrirModal(msj.mensaje.msj)" class="card-img-top img300 imagenChat" :src="msj.mensaje.msj" alt="Imagen no encontrada" title="Enviada"><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                            <p class="sinSeleccion">
                                <!-- <small v-if="(dia != msj.mensaje.fecha.substr(8,2))" class="text-dark">{{msj.mensaje.fecha}}</small><small v-else-if="(parseInt(msj.updatedAt.substr(12,2))== parseInt(msj.mensaje.fecha.substr(12,2)))||(parseInt(msj.updatedAt.substr(12,2))==parseInt(msj.mensaje.fecha.substr(12,2)+12))" class="text-dark">{{msj.mensaje.hora}}</small><small class="text-dark" v-else>{{msj.mensaje.fecha.substr(10,14)}}</small> -->
                                <small class="text-dark">{{msj.mensaje.hora}}</small>
                                <font-awesome-icon v-show="msj.mensaje.status_send == 2 &&(msj.mensaje.status_delivered != 4 && msj.mensaje.status_delivered != 5)" class="text-secondary" icon="check" />
                                <font-awesome-icon v-show="msj.mensaje.status_delivered == 4" class="text-secondary" icon="check-double" />
                                <font-awesome-icon v-show="msj.mensaje.status_delivered == 5" class="text-primary" icon="check-double" />
                                <font-awesome-icon v-show="msj.mensaje.status_send != null" v-if="msj.mensaje.status_send != 2 || (msj.mensaje.status_delivered <= 4 && msj.mensaje.status_delivered >= 5)" class="text-danger ml-1" icon="times" />
                            </p>
                            <!-- {{msj}} -->
                        </div>
                        <div class="avatar" style="padding:0px 0px 0px 10px !important"></div>
                    </div>
                    <!-- Mensajes recibidos-->
                    <div v-else-if="msj.origen == '2'" class="msj macro" :class="{'msj-rtaImg': msj.mensaje.tipo == '2'}">
                        <div class="text text-l">
                            <!-- Tipo TEXTO -->
                            <p v-if="msj.mensaje.tipo == '1'" v-html="msj.mensaje.msj"></p>
                            <!-- Tipo IMG -->
                            <img v-else-if="msj.mensaje.tipo == '2'" @click="abrirModal(msj.mensaje.msj)" class="card-img-top img200 imagenChat" :src="msj.mensaje.msj" alt="Imagen de respuesta" title="Enviada por cliente">
                            <!-- Tipo DOCUMENTO -->
                            <div v-else-if="msj.mensaje.tipo == '3'"><a class="btn btnLink" :href="msj.mensaje.msj" target="_blank"><font-awesome-icon class="text-primary" icon="file-download" /></a><iframe :src="'https://docs.google.com/gview?url='+msj.mensaje.msj+'&embedded=true'" width="320" height="220" style="border: none;"></iframe></div>
                            <!-- Tipo VIDEO -->
                            <video v-else-if="msj.mensaje.tipo == '4'" :src="msj.mensaje.msj" preload="none" controls></video>
                            <!-- Tipo Nota de VOZ/AUDIO -->
                            <audio v-else-if="msj.mensaje.tipo == '5'" :src="msj.mensaje.msj" type="audio/ogg" preload="none" controls></audio>
                            <!-- Tipo UBICACIÓN -->
                            <div v-else-if="msj.mensaje.tipo == '6'" class="imgMapa"><a class="linkMapa" :href="'https://www.google.com/maps/search/?api=1&query='+msj.mensaje.msj+'&heading=-45&pitch=38&fov=80'" target="_blank"><img src="../assets/images/mapa.png"><b class="ubiPaciente">Se recibió ubicación de cliente</b></a></div>
                            <!-- Tipo IMG con TEXTO -->
                            <div v-else-if="msj.mensaje.tipo == '7'"><img @click="abrirModal(msj.mensaje.msj)" class="card-img-top img200 imagenChat" :src="msj.mensaje.msj" alt="Imagen de respuesta" title="Enviada por cliente"><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                            <!-- Tipo VIDEO con TEXTO -->
                            <div v-else-if="msj.mensaje.tipo == '8'"><video :src="msj.mensaje.msj" preload="none" controls></video><br><p class="caption" v-html="msj.mensaje.caption"></p></div>
                            <!-- Tipo Stikers -->
                            <b v-else-if="msj.mensaje.tipo == '9'">Mensaje no soportado</b>
                            <!-- <p class="sinSeleccion"><small v-if="(dia != msj.mensaje.fecha.substr(8,2))">{{msj.mensaje.fecha}} </small><small v-else-if="(parseInt(msj.updatedAt.substr(12,2))== parseInt(msj.mensaje.fecha.substr(12,2)))||(parseInt(msj.updatedAt.substr(12,2))==parseInt(msj.mensaje.fecha.substr(12,2)+12))">{{msj.mensaje.hora}}</small><small v-else>{{msj.mensaje.fecha.substr(10,14)}}</small></p> -->
                        </div>
                        <div class="avatar" style="padding:0px 0px 0px 10px !important"></div>
                    </div>
                    <div v-else-if="msj.origen == '4'" class="alert cambioOrigen text-center mt-4 mb-4" role="alert" :title="msj.mensaje.fecha">
                        
                        <b v-if="msj.mensaje.tipo==1">El cliente pasó al asesor: {{msj.asesor}} </b>
                        <b v-else>El cliente volvió de la cola.</b>
                    </div>
                    <div v-else-if="msj.origen == '5'" class="alert cambioOrigen text-center mt-4 mb-4" role="alert" :title="msj.mensaje.fecha">
                        <b v-if="msj.mensaje.tipo=='2'">{{msj.asesor}} terminó la conversación.</b>
                        <b v-else-if="msj.mensaje.tipo=='3'">Se perdió la sesión con el cliente.</b>
                        <b v-else>El cliente volvió a cola</b>
                    </div>
                </li>
                <div v-if="!sesion" class="alert alert-danger text-center my-4" role="alert">
                        <strong>El cliente</strong> se encuentra fuera de linea, no es posible enviarle mensaje en este momento.
                    </div>
                <li>
                    <hr id="final" style="visibility: hidden;">
                </li>
            </ul>
        </div>
        <div v-else class="container">
            <div class="row">
                <div class="col">
                    <div class="alert cambioOrigen text-center mt-4 mb-4" role="alert">
                        <b>Conversación sin historial</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade modal-acosta" id="img">
            <div @click="modalClick" class="overlaycerrar">
                </div>
                <button @click="rotar(-90)" class="btnGiraIzq"><font-awesome-icon class="h3" icon="undo-alt" /></button>
             
            <div class="contenido-modal" id="imgModal" role="document">
                
                <img id="zoom" class="imgModal" :src="modal" alt="img de paciente" @mousewheel="zoom($event)">
                <div class="d-flex flex-column">
                  <button @click="subirObajar(-10)" class="flechasIMG"><font-awesome-icon class="h6" icon="arrow-up" /></button>
                  <button @click="subirObajar(10)" class="flechasIMG"><font-awesome-icon class="h6" icon="arrow-down" /></button>
                </div>
            </div>
                <button @click="rotar(90)" class="btnGiraDer"><font-awesome-icon class="h3" icon="redo-alt" /></button>
                <span @click="zoom(1)" class="btnZoomPositivo"><font-awesome-icon class="h5" icon="search-plus" /></span>
                <Slider v-model="zoomv" orientation="vertical" class="barraZoom" @change="zoom" @click="zoom()"/>
                <span @click="zoom(-1)" class="btnZoomNegativo"><font-awesome-icon class="h5" icon="search-minus" /></span>
             
        </div>
         <a class="btnAbajo" href="#final" v-show="mensajes.length > 5" >
            <font-awesome-icon icon="angle-double-down" />
        </a>
    </div>
</div> <!-- /#page-content-wrapper -->
</template>
<script> 
import asesorService from '../services/asesor'
import {library} from '@fortawesome/fontawesome-svg-core'
import { faCheck, faCheckDouble, faFileDownload, faAngleDoubleDown, faUndoAlt, faRedoAlt, faSearchPlus, faSearchMinus, faArrowUp, faArrowDown, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCheck, faCheckDouble, faFileDownload, faAngleDoubleDown, faUndoAlt, faRedoAlt, faSearchPlus, faSearchMinus, faArrowUp, faArrowDown, faArrowAltCircleDown)
import Slider from 'primevue/slider'
export default {
    name: 'Mensajes',
    props: ['toggledSidebar', 'sender', 'dataChat'],
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Slider,
    },
    data() {
        return {
            modal: '',
            mensajes: '',
            chatBox: '',
            polling: null,
            dia: '',
            hoy: new Date(),
            ruta: window.location.pathname,
            sessionStorageUser: JSON.parse((sessionStorage.getItem('sesion'))),
            intervalo: 0,
            cuenta: 0,
            zoomv: 0,
            topImg:0,
            hiloCargarMensaje: null,
            asesores: '',
            network: localStorage.getItem('Network'),
            actual: sessionStorage.getItem('sender_actual'),
            sesion: false,
            chat: {
                nombre: '',
                numero: ''
            }
        }
    },
    created() {
        this.listaAsesores()
        this.$bus.$off('ver-mensajes')
        this.$bus.$on('ver-mensajes', (senderid, chat) => {
            this.chat = chat
            this.cargarMsj(senderid)
            sessionStorage.setItem('sender_actual', senderid)
        })

        // const vm = this
        this.$bus.$off('abrirConversacion')
        this.$bus.$on('abrirConversacion', this.verConversacion())
        // this.$bus.$on('abrirConversacion', (chat) => {
        //     console.log(chat)
        //     vm.chat = chat
        //     vm.verConversacion()
        // })

        this.$bus.$off('sendresp-mensajes')
        this.$bus.$on('sendresp-mensajes', senderId => {
            asesorService.verChat(senderId).then(respa => {
                this.mensajes = respa
                // console.log("mensajes",this.mensajes)
            })
        })
        if (this.ruta == '/chat') {
            
            this.intervalo = 3500 //3.5segundos de actualización
        } else {
            this.intervalo = 3600000 //una hora de actualizacion
        }
        
        this.fecha()
        this.$bus.$off('sesionPX_Mensajes')
        this.$bus.$on('sesionPX_Mensajes', sesion => this.sesion = sesion )

    },
    mounted() {
        this.pollData()
        sessionStorage.removeItem('sender_actual')
        console.log(this.dataChat)
        if(this.dataChat){
            this.chat = this.dataChat
        }
    },
    methods: {
        verConversacion() {
            this.cargarMsj(this.sender)
            sessionStorage.setItem('sender_actual', this.sender)
        },
        async listaAsesores() {
            await asesorService.listaDeAsesores().then(respa => {
                this.asesores = respa
            })
        },
        abrirModal(imagen) {
            this.modal = imagen
            this.cuenta = 0
            var image = document.getElementById('imgModal')
            image.setAttribute("style", "transform: rotate(" + this.cuenta + "deg)")
            var modalImg = document.getElementById('img')
            modalImg.classList.add('show')
            
        },
        networkActiva(){
            this.network = asesorService.getNetwork()
        },
        cargarMsj(id) {
                asesorService.verChat(id).then(respa => {
                    var moment = require('moment')
                    moment.locale('es')
                    respa = respa.map(mensaje =>{
                        mensaje.mensaje.hora = moment(moment(mensaje.mensaje.fecha, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
                        if(mensaje.asesor_id!=0){                       
                            if (this.asesores.length) {

                                let indexAsesor = this.asesores.map(asesor => asesor.id).indexOf(+mensaje.asesor_id)
                            
                                mensaje.asesor = indexAsesor == -1 ? 'Tú' : this.asesores[indexAsesor].nombre
                                return mensaje
                            }else{
                                this.cargarMsj(id)
                            }
                            
                        }                
                        return mensaje
                    })
                    this.mensajes = respa
                    // console.log("mensajes",this.mensajes)
                    this.$bus.$emit('bajarScroll', 1)
                })

        },
        cargarMsjAutomatico(id) {
                asesorService.verChat(id).then(respa => {
                    var moment = require('moment')
                    moment.locale('es')
                    respa = respa.map(mensaje =>{
                        mensaje.mensaje.hora = moment(moment(mensaje.mensaje.fecha, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
                        if(mensaje.asesor_id!=0){                        
                            //var buscar = this.asesores.find(asesor => asesor.id == mensaje.asesor_id )
                            //mensaje.asesor = buscar.nombre
                            let indexAsesor =  this.asesores.map(asesor => asesor.id).indexOf(+mensaje.asesor_id)
                            mensaje.asesor = indexAsesor == -1 ? 'Tú' : this.asesores[indexAsesor].nombre
                            return mensaje
                            
                        }                
                        return mensaje
                    })
                    this.mensajes = respa
                })
        },
        fecha() {
            this.dia = this.hoy.toString().substr(8, 2);
        },
        pollData() {
            // clearTimeout(this.hiloGet)
            this.polling = setInterval(() => {
                
                let senderid = sessionStorage.getItem('sender_actual')
                if (senderid) {
                    this.cargarMsjAutomatico(senderid)
                    this.$bus.$emit('existeSender', true)
                } else {
                    this.mensajes = ''
                    this.$bus.$emit('existeSender', false)
                }

            }, this.intervalo)
        },
        modalClick(){
            var modalImg = document.getElementById('img')
            modalImg.classList.remove('show')    

        },
        rotar(angulo){
            
            this.cuenta += angulo
            var image = document.getElementById('imgModal')
            image.setAttribute("style", "transform: rotate(" + this.cuenta + "deg)")
            
        },
        zoom(evento){
            if (evento.deltaY<0){
                this.zoomv+=10
            } else {
                this.zoomv+=-10
            }
            let zoom = 100 + this.zoomv
            if(zoom < 50){
                return
            }
            var image = document.getElementById('zoom')
            image.setAttribute("style", "height:" + zoom + "%!important")
        },
        subirObajar(mouse){
            this.topImg += mouse
            var image = document.getElementById('zoom')
            //image.setAttribute("style", "top:" + this.topImg + "%!important")
            image.style.top = this.topImg + '%'
        },
        formatNumber(numero){
            const regex = /^[0-9]*$/;
            if(regex.test(numero) && numero.length > 6){
                return `(${numero.slice(0, 3)}) ${numero.slice(3, 6)} ${numero.slice(6, numero.length)}`
            }else{
                return numero
            }
        }
    }
}
</script>
