<template>
<div class>
    <table class="table table-hover table-reflow table-bordered">
        <thead class="thead-light">
            <tr>
                <th>Asesor</th>
                <th>Email</th>
                <!-- <th>Foto</th> -->
                <th>Fecha Nacimiento</th>
                <th>Permisos</th>
                <th>Grupo</th>
                <th>Estatus</th>
                <th><button class="btn btn-primary btn-sm" @click="nuevo()"><font-awesome-icon icon="plus"/></button></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="usuario in users" :key="usuario.id">
                
                <th scope="row"><b class="h5">{{usuario.nombre}}</b></th>
                <td class="h5">{{usuario.email}}</td>
                <!-- <td class="h5"><img class="rounded-circle imgPerfilAsesor" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+usuario.id+'.jpg'" :alt="usuario.nombre" height="75px" width="75px"></td> -->
                <td class="h5">{{usuario.fecha_nacimiento}}</td>
                <td class="h5"><h5 class="h5"><span class="badge badge-secondary"> {{usuario.rol_id==1?'Administrador':usuario.rol_id==2?'Moderador': usuario.rol_id==3?'Asesor':'Apoyo'}}</span></h5><span v-show="usuario.estatus==0" class="badge badge-danger">Baja</span></td>
                <td class="h5">{{usuario.grupo_id==1?'Grupo General':usuario.grupo_id==2?'Grupo de AT':usuario.grupo_id==3?'Grupo de Cobranza':usuario.grupo_id==4?'Grupo de Originación':usuario.grupo_id==5?'Grupo de Originación - AT':usuario.grupo_id==6?'Grupo de Procesos y Calidad':usuario.grupo_id==7?'Grupo de Venta En linea':usuario.grupo_id==8?'Grupo de Solicitud de Crédito':'N/A'}}</td>
                <td class="h5">{{usuario.status==1? 'ACTIVO':'INACTIVO' }}</td>
                <td><button class="btn btn-warning btn-sm" @click="editar(usuario)"><font-awesome-icon icon="edit"/></button><button v-if="usuario.status==1" class="btn btn-danger btn-sm" @click="eliminar(usuario)"><font-awesome-icon icon="trash"/></button></td>
            </tr>
        </tbody>
    </table>
    <Sidebar :visible="panelLateral" position="right" class="lateralParametros">
        <h4><b>{{seleccionado.nombre}}</b></h4>
        <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nombre</span>
                </div>
                <input v-model="seleccionado.nombre" type="text" class="form-control">
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Correo</span>
                </div>
                <input v-model="seleccionado.email" type="email" class="form-control">
            </div>
            <div v-if="nuevoUsuario" class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Contraseña</span>
                </div>
                <input v-model="seleccionado.password" type="password" class="form-control">
            </div>
            <div v-else class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nueva Contraseña</span>
                </div>
                <input v-model="seleccionado.password" type="password" class="form-control">
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Cumpleaños</span>
                </div>
                <input v-model="seleccionado.fecha_nacimiento" type="date" class="form-control">
            </div>
            <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                    <span class="input-group-text"># de clientes máximos</span>
                </div>
                <input v-model="seleccionado.num_pacientes" type="number" class="form-control">
            </div>
            <p class="h6 mt-3">Tipo:</p>
            <select v-model="seleccionado.rol_id" class="custom-select custom-select-sm">
                <option v-if="usuarioSesion.rol_id==1" :value="1">Administrador</option>
                <option :value="2">Moderador</option>
                <option :value="3">Asesor</option>
                <option :value="4">Apoyo</option>
            </select>
            <p class="h6 mt-3">Equipo:</p>
            <select v-model="seleccionado.grupo_id" class="custom-select custom-select-sm">
                <option :value="1">Grupo General</option>
                <option :value="2">Grupo de AT</option>
                <option :value="3">Grupo de Cobranza</option>
                <option :value="4">Grupo de Originación</option>
                <option :value="5">Grupo de Originación - AT</option>
                <option :value="6">Grupo de Procesos y Calidad</option>
                <option :value="7">Grupo de Venta En linea</option>
                <option :value="8">Grupo de Solicitud de Crédito</option>
            </select>
            <!-- <p class="h6 mt-3">Orden de los pacientes que recibirá:</p>
            <select v-model="seleccionado.cola" class="custom-select custom-select-sm">
                <option value="ASC">Antiguos</option>
                <option value="DESC">Nuevos</option>
            </select> -->
        </div>
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="cerrar()">Cerrar</button>
        <button class="btn btn-success btn-sm float-right mt-2" @click="guardar()">Guardar <font-awesome-icon icon="save"/></button>
    </Sidebar>
</div>
</template>
<script>
import userservices from "../../services/asesor"
import Sidebar from 'primevue/sidebar'
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,faReply,faFileExcel,faTrash, faPlus
} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faReply, faFileExcel,faTrash, faPlus);
export default {
    data() {
        return {
            users: null,
            usuarioSesion:JSON.parse(sessionStorage.getItem('sesion')),
            panelLateral:false,
            seleccionado:{},
            esperar:false,
            nuevoUsuario:false,
        }
    },
    userservice: null,
    components:{
      FontAwesomeIcon, Sidebar
    },
    created() {
      
        //this.userservice = new userservice();
    },
    mounted() {
        this.inicio()
        
    },
    methods: {
        inicio(){
            userservices.usuarios().then(data => {
                data.sort((a,b) =>{
                    return a.id - b.id
                })
                if(this.usuarioSesion.rol_id==2){
                    this.users=data.filter(x=> x.rol_id>=3)
                }else{
                    this.users=data
                }
            })
        },
        nuevo(){
            this.panelLateral=true
            this.seleccionado={facebook:0,estatus:1,num_pacientes:0,rol_id:3,fecha_nacimiento:'1990-01-01',grupo_id:2, cola:'ASC'}
            this.nuevoUsuario=true
        },
        editar(user){
            this.nuevoUsuario=false
            this.seleccionado=user
            this.seleccionado.fecha_nacimiento?false:this.seleccionado.fecha_nacimiento='1990-01-01'
            this.panelLateral=true
        },
        eliminar(user){
            user.estatus=0
            let obj = {
                id: user.id,
                estatus:0
            }
            userservices.editarUsuario(obj).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                }).finally(()=>{
                this.esperar=false 
                })
        },
        guardar(){
            delete this.seleccionado.sesion
            this.esperar=true
            if(this.nuevoUsuario){
                userservices.nuevoUsuario(this.seleccionado).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                }).finally(()=>{
                this.esperar=false 
                })
            }else{
                userservices.editarUsuario(this.seleccionado).then(resp =>{
                    if(resp){
                        this.cerrar()
                        this.inicio()
                    }
                }).finally(()=>{
                this.esperar=false 
                })
            }
        },
        cerrar(){
            this.panelLateral=false
        }
    }
}
</script>
