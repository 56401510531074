<template>
<DataTable :value="Treporte"  removableSort ref="tablaReporte">
    <template #header>
        <div style="text-align: right">
            <Button class="btn btn-success" label="Export" @click="exportCSV($event)"><span class="d-none d-sm-none d-md-block">Exportar a Excel</span> <font-awesome-icon class="ml-1 d-block d-sm-block d-md-none" icon="file-excel"/><i class="far fa-file-excel"></i></Button>
        </div>
    </template>
    <Column v-for="col of columnas" :field="col.field" :header="col.header" :key="col.field" :sortable="true" ></Column>
</DataTable>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {
    library
} from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faFileExcel
} from "@fortawesome/free-solid-svg-icons"
library.add(faFileExcel)
export default {
    name: 'Reportedinamico',
    props: {
        Treporte:Array,
        columnas: null
    },
    data() {
        return {
            loading: false,
            columns: {}
        }
    },
    created(){
        this.columnsrep1 = [
        {
            field: "count",
            header: "Clientes",
        },
        {
            field: "createdAt",
            header: "Fecha",
        },
        ]

    },
    components: {
        'DataTable': DataTable,
        'Column': Column,
        'font-awesome-icon': FontAwesomeIcon
    },
    methods: {
        exportCSV() {
            this.$refs.tablaReporte.exportCSV();
        }
    }
}
</script>
