import api from './api'
import apiFB from './apiFB'
// import authHeader from './auth-header';

let service = {}
let network = 'WA'

service.actualizarNetwork = function(networks){
    network = networks
}
service.getNetwork = function(){

    return network
}
service.pedirPaciente = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    if(network=='WA'){
        return api.post('/asesor/pedirpaciente', { asesorId: asesor.id }).then(res => res.data)
    }else{
        return apiFB.get('/asesor/getcola/'+asesor.id).then(res => res.data)
    }
}

service.pacientesAtendiendo = function() {
    let asesor = JSON.parse(sessionStorage.getItem('asesorId'))
    
    if(network=='WA'){
        return api.post('/asesor/pacientes', { asesorId: asesor }).then(res => res.data).catch(err => {
            if (401 === err.response.status) {
                window.location = '/'
                console.log('Token Expirado ', err)
            }
        })
    }else if(network=='WAni'){
        return api.post('/nicaragua/asesor/pacientes', { asesorId: asesor }).then(res => res.data)
    }else{
        return apiFB.get('/asesor/paciente/'+ asesor).then(res => res.data)
    }
}

service.verChat = function(senderId) {
    if((network=='FB') && (window.location.pathname == '/chat')){
        return apiFB.get('/asesor/paciente/inbox/'+senderId).then(res => res.data)
    }else{
        return api.post(network=='WAni'?'/nicaragua/asesor/inbox/paciente':'/asesor/inbox/paciente', { senderId }).then(res => res.data).catch(err => {
            if (401 === err.response.status) {
                window.location = '/'
                // this.$gtag.event('tokenExpirado',{
                //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                //     'event_label': '12hrs conectado',
                //     'value': 1
                // })
            }
        })
    }
}

service.sendMensaje = function(senderId, mensaje) {
    if (mensaje == '' || senderId == '') {
        return
    }

    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender_actual')
    if(senderId!=sender){
        senderId=sender
    }
    if(network=='WA'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/asesor/enviarmensaje', datos).then(res => res.data)
    }else if(network=='WAni'){
        let datos = {
            asesorId: asesor.id,
            senderId: senderId,
            mensaje: mensaje,
            tipo: 'ASESOR_MSJ'
        }
        return api.post('/nicaragua/asesor/enviarmensaje', datos).then(res => res.data)
    }else{
        let datos = {
            senderid: senderId,
            asesorId: asesor.id,
            mensaje: mensaje
        }
        return apiFB.post('/asesor/send/', datos).then(res => res.data)
    }
}


service.sendImage = function(senderId, datos) {
    const sender = sessionStorage.getItem('sender_actual')
    if(senderId!=sender){
        senderId=sender
    }
    if(network=='WA'){
        return api.post('/asesor/enviarImagen', datos).then(res => res.data)
    }else{
        return apiFB.post('/asesor/enviarImagen', datos).then(res => res.data)
    }
}


service.pacientesEnCola = function() {
    if((network=='WA') && (window.location.pathname == '/chat')){
        return true
        //return api.get('/asesor/pacientesencola').then(res => res.data)
    }else{
        return apiFB.get('/asesor/cola').then(res => res.data)
    }
}

service.pacientesAtendiendoEnCola = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        asesorId: asesor.id
    }
    return api.post('/asesor/pacientesatendiendo', datos).then(res => res.data)
}

service.finalizarPaciente = function(senderId) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        senderId: senderId,
        asesorId: asesor.id
    }
    if(network=='WA'){
        return api.post('/asesor/paciente/finalizar', datos).then(res => res.data)
    }else if(network=='WAni'){
        return api.post('/nicaragua/asesor/paciente/finalizar', datos).then(res => res.data)
    }else{
        return apiFB.get('/asesor/finalizar/'+ senderId +'/'+asesor.id).then(res => res.data)
    }
}
service.finalizarPacienteEncuesta = function(senderId) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        sender_id: senderId,
        asesor_id: asesor.id,
        encuesta_id: 2
    }
    return api.post('/asesor/encuesta', datos).then(res => res.data)
}
service.finalizacionProgramada = function(senderId) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        senderid: senderId,
        asesor_id: asesor.id,
        flujo: 10
    }
    return api.post('/asesor/pacienteatendido', datos).then(res => res.data)
}
service.listaDeAsesores = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.post('/asesores', { asesorId: asesor.id }).then(res => res.data).catch(err => {
        if (401 === err.response.status) {
            window.location = '/'
            // this.$gtag.event('tokenExpirado',{
            //     'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
            //     'event_label': '12hrs conectado',
            //     'value': 1
            // })
        }
    })
}

service.traspasarPaciente = function(senderId, asesor_traspaso, comentario) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        "paciente_id": senderId,
        "asesor_origen": asesor.id,
        "asesor_traspaso": asesor_traspaso,
        "comentario": comentario
    }
    if((network=='FB') && ((window.location.pathname == '/chat')||(window.location.pathname == '/dashboard/monitor'))){
        return apiFB.post('/asesor/traspaso', datos).then(res => res.data)
    }else if(network=='WAni'){
        return api.post('/nicaragua/asesor/transferirpaciente', datos).then(res => res.data)
    }else{
        return api.post('/asesor/transferirpaciente', datos).then(res => res.data)
    }
}

service.listaMotivos = function() {
    return api.get('/motivos').then(res => res.data)
}

service.respuestas = function(text) {
    return api.post('/buscarrespuestas', { msj: text }).then(res => res.data)
}

service.traspasarPacientesMasivo = function(asesor_traspaso, comentario) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        "asesor_origen": asesor.id,
        "asesor_traspaso": asesor_traspaso,
        "comentario": comentario
    }
    return api.post('/asesor/transferirpacientes', datos).then(res => res.data)
}
service.traspasarPacientesConEtiqueta= function(asesor_traspaso, comentario, etiqueta) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        "asesor_origen": asesor.id,
        "asesor_traspaso": asesor_traspaso,
        "comentario": comentario,
        "etiqueta": etiqueta
    }
    if(network=='WA'){
    return api.post('/asesor/traspasoetiquetas', datos).then(res => res.data)
    } else{
    return api.post('/nicaragua/asesor/traspasoetiquetas', datos).then(res => res.data)
    }
}
service.traspasarPacientesParametros = function(asesor_traspaso) {
    let datos = {
        "asesor_origen": asesor_traspaso,
        "asesor_traspaso": asesor_traspaso,
        "comentario": 1,
        "mandarCola": true
    }
    return api.post('/asesor/transferirpacientes', datos).then(res => res.data)
}


service.traspasarPacientePlataforma = function(senderId) {
    let datos = {
        paciente_id: senderId
    }
    return api.post('/asesor/traspasoplataforma', datos).then(res => res.data)
}

service.pacienteEspera = function() {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender')

    let datos = {
        senderId: sender,
        asesorId: asesor.id
    }
    return api.post('/asesor/pacienteespera', datos).then(res => res.data)
}

service.consultarRSV = function(rsv) {
    let senderid = sessionStorage.getItem('sender_actual')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    if(network=='WA'){
        return api.post('/asesor/estatusresultados', { rsv, senderid, asesor_id }).then(res => res.data)
    }else{
        return apiFB.get('/asesor/resultados/'+asesor.id +'/'+senderid+'/'+rsv).then(res => res.data)
    }
}
service.enviarRSV = function(rsv) {
    let senderid = sessionStorage.getItem('sender_actual')


    if(network=='WA'){
        return api.post('/asesor/enviarresultados', { rsv, senderid }).then(res => res.data)
    }else{
        let asesor = JSON.parse(sessionStorage.getItem('sesion'))
        let senderid = sessionStorage.getItem('sender_actual')
        let datos = {
            "senderid":senderid,
            "asesor_id":asesor.id
        }
        return apiFB.post('/asesor/resultados/enviar',datos).then(res => res.data)
    }
}
service.imgLink = function() {
    let senderid = sessionStorage.getItem('sender_actual')
    return api.post('/asesor/imagenesrv', { senderid }).then(res => res.data)
}
service.rsvCorreo = function(correo) {
    let senderid = sessionStorage.getItem('sender_actual')
    return api.post('/asesor/enviarresultadoscorreo', { senderid, correo }).then(res => res.data)
}
service.EstudioSuc = function(sucursal_id){
    return api.get('/asesor/sucursal/estudios?sucursal_id='+sucursal_id).then(data=>data.data)
}

service.subEstudios = function(sucursal_id, estudio_id, tipo){
    return api.get('/asesor/sucursal/subestudios?sucursal_id='+sucursal_id+'&estudio_id='+estudio_id+'&tipo='+tipo).then(data=>data.data)
}
service.cotizarPrecios = function(sucursal_id, estudio_id, pr, tipo){
    let senderid = sessionStorage.getItem('sender_actual')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    if(tipo==1){
        return api.get('/asesor/sucursal/subestudio?sucursal_id='+sucursal_id+'&estudio_id='+estudio_id+'&subestudio_id='+pr+'&senderid='+senderid+'&asesor_id='+asesor_id).then(data=>data.data)

    } else{
        return api.get('/asesor/sucursal/subestudio?sucursal_id='+sucursal_id+'&estudio_id='+estudio_id+'&paquete_id='+pr+'&senderid='+senderid+'&asesor_id='+asesor_id).then(data=>data.data)

    }
}

service.flujos = function (flujo){
    let senderid = sessionStorage.getItem('sender_actual')||sessionStorage.getItem('sender')
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    return api.post('/asesor/enviaraflujo', { senderid, asesor_id, flujo }).then(res => res.data)
}


service.ubicarClinica = function(sucursal) {
    return api.post('/asesor/sucursales', { sucursal }).then(res => res.data)
}
service.enviarUbicacionClinica = function(clinica) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender_actual')

    let datos = {
        senderid: sender,
        asesorid: asesor.id,
        clinica_id: clinica
    }
    return api.post('/asesor/enviarsucursal', datos).then(res => res.data)
}

service.cerrarSesion = function (){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let asesor_id = asesor.id
    return api.post('/closed', { asesor_id }).then(res => res.data)
}

service.estatus = function(asesor, estatus) {
    let datos = {
        asesor_id: asesor,
        estatus: estatus
    }
    return api.post('/asesor/estatus', datos).then(res => res.data)
}

service.enviarPdf = function(nombreArchivo, url) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let sender = sessionStorage.getItem('sender')
    let datos = {
        asesor_id: asesor.id,
        senderid: sender,
        caption: nombreArchivo,
        pdf: url
    }
    return api.post('/pdf/send', datos).then(res => res.data)
}

service.etiquetar = function(sender, obj) {
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        asesor_id: asesor.id,
        senderid: sender,
        etiquetas: obj
    }
    if(network=='WA'){
        return api.post('/asesor/etiqueta', datos).then(res => res.data)
    } else if(network=='WAni'){
        return api.post('/nicaragua/asesor/etiqueta', datos).then(res => res.data)
    } else {
        return apiFB.post('/asesor/etiqueta', datos).then(res => res.data)
    }
}

service.Atendidos = function(){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    return api.get('/asesor/atendidos?asesor_id='+asesor.id).then(data=>data.data)
}

service.mandarEspera = function(sender){
    let asesor = JSON.parse(sessionStorage.getItem('sesion'))
    let datos = {
        asesor_id: asesor.id,
        senderid: sender,
    }
    return api.post('/asesor/espera', datos).then(data=>data.data)
}
service.covidInfo = function() {
    return api.get('/covid/informacion').then(res => res.data)
}

service.laLuchaInfo = function() {
    return api.get('/asesor/informacion/OCTUBREROSA').then(res => res.data)
}

service.getInfo = function() {
    return api.get('/asesor/informacion/GENERAL').then(res => res.data)
}

service.InfoEnviar = function(id) {
    let sender = JSON.parse(sessionStorage.getItem('sender_actual'))
    let datos = {
        id: id,
        senderid: String(sender),
    }
    return api.post('/asesor/informacion/enviar', datos).then(res => res.data)
}

service.notificaciones = function() {
    return api.get('/api/asesor-notificaciones').then(res => res.data)
}
service.bajaNotificacion = function(id) {
    return api.put('/api/asesor-notificaciones/delete/'+id).then(res => res.data)
}
service.vistoNotificacion = function(id) {
    return api.put('/api/asesor-notificaciones/visto/'+id).then(res => res.data)
}
service.usuarios = function() {
    return api.get('/asesor').then(res => res.data)
}
service.editarUsuario = function(datos) {
    return api.put('/asesor',datos).then(res => res.data)
}
service.nuevoUsuario = function(datos) {
    return api.post('/asesor',datos).then(res => res.data)
}

export default service