<template>
<div class>
    <DataTable :value="conversacion" ref="conversaciones" :filters="filters" :paginator="true" :loading="status" :rows="8" :selection.sync="seleccionado" selectionMode="single"  data-key="senderid" @row-select="onRowSelect" @row-unselect="onRowUnselect" class="p-datatable-responsive">
        <template #header>
            Conversaciones
            <div style="text-align: left">
                <i class="pi pi-search" style="margin: 4px 4px 0px 0px;"></i>
                <InputText v-model="filters['global']" placeholder="Filtro global" size="30" />
            </div>
            <div style="text-align: right">
                <Button class="btn btn-success top10" label="Export" @click="exportCSV($event)">
                    <span class="d-none d-sm-none d-md-block">Exportar a Excel</span> <font-awesome-icon class="d-block d-sm-block d-md-none" icon="file-excel"/>
                </Button>
            </div>
        </template>
        <template #loading>
            <span class="loading-text">Hola</span>
        </template>
        <Column field="nombre" header="Clientes" :sortable="true"></Column>
        <Column field="senderid" header="Numero de telefono" :sortable="true"></Column>
        <Column field="asesor" header="Atendido por" :sortable="true"></Column>
        <!-- Column class="d-none" field="rEncuesta" header="Calificación de encuesta" :sortable="true"  >
            <Rating :value="rEncuesta" :stars="5" :cancel="false" :readonly="true" />
      </Column-->
        <Column field="fecha" header="Hora y fecha" :sortable="true"></Column>
    </DataTable>
    <Dialog class="conversaciones" :closable="false" :modal="true" :header="'Conversación de ['+this.nombre+'] de numero  +'+this.numero+' con '+this.asesor+' el dia de '+this.fecha" :visible.sync="chat">
        <mensajes style="z-index:1044" :sender="this.numero" :dataChat="dataChat" />
        <template #footer>
             <Button class="btn btn-primary btn-sm float-left" @click="inbox" data-target="#traspasarChat">
                <font-awesome-icon class="mr-1" icon="reply" /><span class="ocultoMovil"> Enviar con asesor</span>
             </Button>
             <Button class="btn btn-info btn-sm float-left" @click="bot">
                <font-awesome-icon class="mr-1" icon="robot" /><span class="ocultoMovil"> Enviar al bot</span>
             </Button>
             <Button class="btn btn-success btn-sm" @click="revivirSesion()">
                <i class="icon-sd-send"></i><span class="ocultoMovil"> Enviar Bienvenida</span>
             </Button>
            <Button class="btn btn-danger btn-sm" @click="cerrar">
                <font-awesome-icon class="mr-1" icon="times" /><span class="ocultoMovil">Cerrar conversacion</span>
            </Button>
        </template>
    </Dialog>
</div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Mensajes from "../../Mensajes.vue";
import asesorServices from '../../../services/asesor';
// import MultiSelect from 'primevue/multiselect';
import InputText from "primevue/inputtext";
//import Rating from 'primevue/rating';
import Dialog from "primevue/dialog";
import reportesServices from "../../../services/reportes"
import mensaje from "../../../services/mensaje"
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,
    faReply, faFileExcel
} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faReply, faFileExcel);
export default {
    name: "TConversaciones",
    components: {
        DataTable: DataTable,
        Column: Column,
        // 'MultiSelect': MultiSelect,
        InputText: InputText,
        "font-awesome-icon": FontAwesomeIcon,
        // Rating,
        Dialog,
        Mensajes
    },
    props: {
        fechaI :null,
        fechaF :null,
    },
    data() {
        return {
            filters: {},
            conversacion: [{"id":-1,"nombre":"Selecciona","senderid":"una fecha","fecha":"el historial","asesor_id":null,"ejecutivo":null,"asesor":"para ver"}],
            seleccionado: null,
            chat: false,
            numero: null,
            nombre: '',
            asesor: '',
            fecha: '',
            status: false,
            dataChat: null
        };
    },
    created(){
        this.$bus.$off('updateHistorial')
        this.$bus.$on('updateHistorial', () => {
            this.status=true
            this.cargarHistorialChats()
        })
        this.$bus.$off('abrirConversacion')
        this.$bus.$on('abrirConversacion', () => {
            this.status=true
            this.cargarHistorialChats()
        })
    },
    methods: {
        exportCSV() {
            this.$refs.conversaciones.exportCSV();
        },
        abrir() {
            this.chat = true;
        },
        cerrar() {
            this.chat = false;
            this.seleccionado = null;
        },
        inbox() {
            this.$bus.$emit("abrirInbox", this.seleccionado.senderid)
        },
        bot(){
            asesorServices.flujos(0)
        },
        onRowSelect() {
            if(this.seleccionado.id!=-1){
                this.chat = true;
                this.numero = this.seleccionado.senderid;
                this.nombre = this.seleccionado.nombre;
                if (this.seleccionado.asesor == '') {
                    this.asesor = 'Asistente Virtual'
                } else {
                    this.asesor = this.seleccionado.asesor;
                }
                this.fecha = this.seleccionado.fecha;
                this.dataChat = {
                    nombre: this.nombre,
                    numero: this.numero
                }
                this.$bus.$emit("abrirConversacion", 1)
                sessionStorage.setItem('sender',this.numero)  
            }else{
                this.onRowUnselect()
            }
        },
        onRowUnselect() {
            this.seleccionado = null;
        },
        cargarHistorialChats() {
            reportesServices.getHistorialChats(this.fechaI,this.fechaF).then(resp => {
                var moment = require('moment')
                moment.locale('es')
                resp = resp.map(chat =>{
                    chat.asesor = chat.ejecutivo ? chat.ejecutivo.nombre: 'Asistente Virtual'
                    chat.fecha = moment(chat.fecha).format('YYYY MMMM DD, dddd, hh:mm:ss a');
                    return chat
                })
                this.conversacion = resp;
                this.status=false
                this.$bus.$emit('respuestaStatus', true)
            });
        },
        revivirSesion(){
            this.chat = false
            mensaje.envio(this.numero, "sd_bienvenida", []).then(hsm=>{
                
                this.chat = hsm.status
            })
        }
    },
    mounted() {
        //this.cargarHistorialChats();
        this.$bus.$emit('respuestaStatus', true)
    }
};
</script>
