<template>
<div class="container-fluid">
    <div  class="my-2">

        <div class="row">
            <h5 class="mx-4"><font-awesome-icon icon="moon"/>  Dormir opción Asesor:</h5> <InputSwitch v-model="checked" @click="cambiarSwitch" /> 
        </div>
        <div class="p-4 d-flex flex-row w-100 justify-content-center align-items-center">
           <div class="col font-weight-bolder" style="font-size: 14px">
                Cola Whatsapp: <b>{{cola.whatsapp.cola}}</b>
           </div>
        </div>
        <table class="table table-hover table-reflow table-bordered">
            <thead class="thead-light">
                <tr>
                    <th>Asesor</th>
                    <th>Inbox <i class="icon-whatsapp" style="font-size:14px"></i></th>
                    <th>En pendientes</th>
                    <th>Automatización <i class="icon-whatsapp" style="font-size:14px"></i></th>
                    <!-- <th>Automatización <i class="icon-messenger-new"></i></th> -->
                    <th>Controles <i class="icon-whatsapp" style="font-size:14px"></i></th>
                    <!-- <th>Controles <i class="icon-messenger-new"></i></th> -->
                    <th>MAX # CLIENTES</th>
                    <th>Tipo de Cola</th>
                    <th>Editar</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="asesor in asesores" :key="asesor.id">
                    
                    <th scope="row"><b class="h5">{{asesor.nombre}}</b> <br><span v-show="asesor.online" class="parpadea texto-verde">En linea</span><span v-show="!asesor.online" class="texto-verde text-danger">{{asesor.fin_sesion==null?'No conectado':'Ultima conexion: '+ asesor.fin}}</span></th>
                    <td class="h5">{{asesor.ATENDIENDO}}</td>
                    <td class="h5">{{asesor.ESPERA_1}}</td>
                    <td><div v-if="asesor.sesion" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Funcionando <font-awesome-icon spin icon="cog"/></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Detenida <font-awesome-icon icon="cog"/></div> </td>
                    <!-- <td><div v-if="asesor.facebook" class="rounded bg-success text-white font-weight-bold p-2 text-center"> Funcionando <font-awesome-icon spin icon="cog"/></div><div v-else class="rounded bg-info text-white font-weight-bold p-2 text-center"> Detenida <font-awesome-icon icon="cog"/></div> </td> -->
                    <td><button @click="cambiarEstatus(asesor.id, !asesor.sesion)" class="btn  btn-sm" :class="{'btn-info':!asesor.sesion, 'btn-secondary':asesor.sesion}"><font-awesome-icon v-if="asesor.sesion" icon="pause"/><font-awesome-icon v-else icon="play"/> <span v-if="asesor.sesion">Detener</span><span v-else>Iniciar</span> <span v-if="esperar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button>
                    <button @click="mandarCola(asesor.id)" class="btn btn-primary btn-sm ml-1">A cola</button>
                    </td>
                    <!-- <td><button @click="guardar(asesor.id, asesor.sesion, (asesor.facebook?0:1), asesor.num_pacientes, asesor.cola)" class="btn  btn-sm" :class="{'btn-info':!asesor.facebook, 'btn-secondary':asesor.facebook}"><font-awesome-icon v-if="asesor.facebook" icon="pause"/><font-awesome-icon v-else icon="play"/> <span v-if="asesor.facebook">Detener</span><span v-else>Iniciar</span> <span v-if="esperar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </button> </td> -->
                    <td class="h5">{{asesor.num_pacientes}} </td>
                    <td><h5 class="h5"><span class="badge badge-secondary"><font-awesome-icon v-show="asesor.cola=='ASC'" icon="arrow-alt-circle-up"/><font-awesome-icon v-show="asesor.cola=='DESC'" icon="arrow-alt-circle-down"/> {{asesor.cola=='ASC'?'Antiguos':'Nuevos'}}</span></h5></td>
                    <td><button class="btn btn-warning btn-sm" @click="editar(asesor)"><font-awesome-icon icon="edit"/></button></td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <Sidebar :visible="panelLateral" position="right" class="lateralParametros">
        <h4><b>{{seleccionado.nombre}}</b></h4>
        <div class="contenidoAsesorParametros mt-4">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text"># de clientes máximos</span>
                </div>
                <input v-model="seleccionado.num_pacientes" type="number" class="form-control">
            </div>
            <p class="h6 mt-3">Orden de los cliente que recibirá:</p>
            <select v-model="seleccionado.cola" class="custom-select custom-select-sm">
                <option value="ASC">Antiguos</option>
                <option value="DESC">Nuevos</option>
            </select>
        </div>
        <button class="btn btn-secondary btn-sm float-left mt-2" @click="panelLateral=false">Cerrar</button>
        <button class="btn btn-success btn-sm float-right mt-2" @click="guardar(seleccionado.id, seleccionado.sesion, seleccionado.facebook, seleccionado.num_pacientes, seleccionado.cola)">Guardar <font-awesome-icon icon="save"/></button>
    </Sidebar>
</div>
</template>
<style>
.texto-verde {
  font-size:14px;
  font-weight:bold;
  color:#4d9702;
}
.parpadea {
  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import controlesServices from '../../services/controles'
import { faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
library.add(faPlay, faPause, faCog, faMoon, faArrowAltCircleUp, faArrowAltCircleDown, faEdit, faSave)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import asesorServices from '../../services/asesor'
import InputSwitch from 'primevue/inputswitch'
import SocketIo from 'socket.io-client'
import Sidebar from 'primevue/sidebar'
export default {
    name: 'ConfiAsesor',
    data() {
        return {
            asesores: null,
            esperar: false,
            switch: false,
            checked:false,
            panelLateral:false,
            cola:null,
            nicaragua:{},
            tab:1,
            sesion: JSON.parse(sessionStorage.getItem('sesion')),
            seleccionado:{ "id": 0, "nombre": "Aqui aparece el nombre del cliente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" },
        }
    },
    components:{
        FontAwesomeIcon,
        InputSwitch,
        Sidebar,
    },
    created(){
        let data = {
            query: {
                "Tipo": "3"
            }
        }
        let dataCola = {
            query: {
                "Tipo": "1"
            }
        }

        var socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
        socket.on('parametros',  (datos) => {
            var moment = require("moment")
            moment.locale("es")
            datos.map(x =>{
                x.lastconnection = moment(x.lastconnection)
                x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
                if((x.lastconnection.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
                    x.online=true
                }else{
                    x.online=false
                }
                if(this.sesion.rol_id==1){
                    return x

                } else{
                    return x.rol_id==3
                }
            })
            datos.sort((a,b) =>{
                return b.id - a.id
            })
            datos.sort((a,b) =>{
                return (a.online === b.online)? 0 : a.online? -1 : 1
            })
            datos.sort((a,b) =>{
                return b.ATENDIENDO - a.ATENDIENDO
            })
            this.asesores = datos
        })
        var socketCola = SocketIo.connect('https://valdez-socket.auplex.mx', dataCola)
        socketCola.on('pedirColaRedes',  (payload) => {
            this.cola=payload
        })
        let colaNicaragua = {
            query: {
                "Tipo": "2"
            }
        }
        var socketNicaragua = SocketIo.connect('https://valdez-socket.auplex.mx', colaNicaragua)
        socketNicaragua.on('pedirColaNicaragua',  (payload) => {
            this.nicaragua=payload
        })
        
    },
    mounted(){
        this.get()
    },
    methods: {
        get(){
            // reportesServices.asesorSesion().then(datos => {
            //     var moment = require("moment")
            //     moment.locale("es")
            //     datos.map(x =>{
            //         x.lastconnection = moment(x.lastconnection)
            //         x.fin = moment(x.fin_sesion, 'YYYY-MM-DD HH:mm').calendar()
            //         if((x.lastconnection.diff(moment(), 'days')==0)&&(x.fin_sesion==null)){
            //             x.online=true
            //         }else{
            //             x.online=false
            //         }
            //         return x
            //     })
            //     this.asesores = datos
            // })
            controlesServices.Control().then(resp => {
                resp.map(x => {
                    if(x.id==1){
                        this.switch=x
                    } else{
                        return
                    }
                    return x
                })
                
                if(this.switch.status==0){
                    this.checked=false
                }else{
                    this.checked=true
                }
            })
        },
        cambiarEstatus(id, estatus){
            this.esperar=true
            asesorServices.estatus(id, estatus).then(() =>{
                this.get()
                this.esperar=false
            })
        },
        mandarCola(a){
            this.esperar=true
            asesorServices.traspasarPacientesParametros(a).then(()=>{
                    this.get()
                    this.esperar=false
                })
        },
        cambiarSwitch(){
            let variable
            variable = this.checked ? 0:1
            controlesServices.CambiarControl(1, variable).then(() => {
                this.get()
            })
        },
        editar(asesor){
            this.seleccionado=asesor
            this.panelLateral=!this.panelLateral
        },
        guardar(a,b,c,d,e){
            this.esperar=true
            
            controlesServices.updateAsesorConfig(a,b,c,d,e).then(res=>{
                if(res){
                    this.panelLateral=false
                    this.seleccionado={ "id": 0, "nombre": "Aqui aparece el nombre del cliente", "ATENDIENDO": "Numero de atendidos", "ESPERA_1": "Px en Pendientes", "sesion": true, "facebook": 0, "num_pacientes": 35, "cola": "DESC" }
                }
                this.esperar=false
            })
        }
    },
}
</script>
