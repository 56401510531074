import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

import Reporte from '@/components/Dashboard/Reportes.vue'
import Historial from '@/components/Dashboard/Historial.vue'
import Monitor from '@/components/Dashboard/Monitor.vue'
import Parametros from '@/components/Dashboard/Parametros.vue'
import Incidencias from '@/components/Dashboard/Incidencias.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Chat - Iniciar Sesion',
      metaTags: [
        {
          name: 'description',
          content: 'Plataforma diseñada para la atención de clientes'
        },
        {
          property: 'og:description',
          content: 'Plataforma diseñada para la atención de clientes'
        }
      ]
    }
  },
  {
    path: '/chat',
    name: 'chat',
    meta: { title: 'Inbox' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Chats" */ '../views/Chat.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { title: 'Dashboard' },
    children: [{
      path: '/dashboard/historial',
      name: 'historial',
      component: Historial,
      meta: { title: 'Dashboard' },
    },
    {
      path: '/dashboard/monitor',
      name: 'monitor',
      component: Monitor,
      meta: { title: 'Monitor - Chat SD' },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Monitor,
      meta: { title: 'Dashboard - Chat SD' },
    },
    {
      path: '/dashboard/reportes',
      name: 'reportes',
      component: Reporte,
      meta: { title: 'Reportes - Chat' },
    }, {
      path: '/dashboard/incidencias',
      name: 'incidencias',
      component: Incidencias,
      meta: { title: 'Incidencias - Chat' },
    },
    {
      path: '/dashboard/parametros',
      name: 'parametros',
      component: Parametros,
      meta: { title: 'Parámetros - Chat' },
    }
    ],
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Chat'
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE
})
export default router
