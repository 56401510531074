let config = {
    BASEURL: 'https://api-wa.valdezbaluarte.mx',
    baseurlFB: 'https://api-messenger.valdezbaluarte.mx',
    baseurlReportes: 'https://api-wa.valdezbaluarte.mx',
    baseurlSIS: 'https://api-wa.valdezbaluarte.mx',
    baseDevoluciones: 'https://api-wa.valdezbaluarte.mx',
    baseDashboard: 'https://api-wa.valdezbaluarte.mx',
    baseMarketing: 'https://api-wa.valdezbaluarte.mx'
}

export default config