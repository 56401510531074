import api from './api'
let service = {}

service.envio = function(sender, template, parametros) {
    let datos = [{
        telefono: sender,
        tipohsm: template,
        msj: parametros,
    }]
    return api.post('/mensaje/envio', datos).then(res => res.data)
}

export default service