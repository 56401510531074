<template>
  <div>
    <h2>Reportes</h2>
    <div class="MenuReportes">
      <div class="row">
        <div class="form-group col-md-3 col-11">
          <label for="fechaInicial">Fecha inicial</label>
          <input
            v-model="fecha_inicio"
            type="date"
            id="fecha_inicio"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-3 col-11">
          <label for="fechaFinal">Fecha final</label>
          <input
            v-model="fecha_final"
            type="date"
            id="theDate"
            class="form-control"
          />
        </div>
        <div class="form-group col-md-3 col-11">
          <label for="reporte">Reporte</label>
          <select
            v-model="reporte"
            class="form-control"
            v-on:change="signalChange"
            required
          >
            <option disabled value="" hidden>Seleccione una opción</option>
            <option v-for="item in brands" :key="item.id" :value="item">
              {{ item.reporte }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3 col-11 my-auto">
          <button
            type="button"
            class="btn btn-primary"
            @click="consultarReporte"
            :disabled="!status"
          >
            Consultar
            <span
              v-if="!status"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
    <div class="alert alert-info" v-show="datos && datos.length == 0">
      Sin resultados en este rango de fechas
    </div>

    <h5 v-show="vsrep1">*Éste reporte solo toma en cuenta la fecha inicial</h5>
    <b v-show="reporte.descripcion">Descripción del reporte:</b>
    <p v-html="reporte.descripcion"></p>
    <h3 v-show="cvshow2">Grafica de clientes atendidos</h3>
    <Chart
      type="line"
      :height="ochenta"
      :data="basicData"
      v-show="cvshow2"
      :options="options"
    />
    <Chart
      type="line"
      :height="ochenta"
      :data="basicData"
      v-show="porHora"
      :options="options"
    />

    <!-- <GPacienteAtendidos v-show="reporte == ''" :height="ochenta" /> -->
    <tabla v-show="reporte != ''" :Treporte="datos" :columnas="tabla" />
  </div>
</template>

<style scoped>
.MenuReportes {
  margin-bottom: 1rem;
}

.p-autocomplete-dd {
  margin-left: 3rem;
}

.p-inputtext {
  width: 180px;
}
</style>

<script>
import reportesServices from "../../services/reportes";
import Chart from "primevue/chart";
import Tabla from "./Tablas/ReporteDinamico";
export default {
  name: "Reportes",
  components: {
    Chart: Chart,
    Tabla: Tabla,
  },
  created() {
    let today = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(today.getDate() - 1);
    this.columnsrep1 = [
      {
        field: "count",
        header: "Clientes",
      },
      {
        field: "createdAt",
        header: "Fecha",
      },
    ];
    this.columnsrep2 = [
      {
        field: "count",
        header: "Clientes",
      },
      {
        field: "createdAt",
        header: "Fecha",
      },
    ];
    this.columnsrep3 = [
      {
        field: "count",
        header: "Clientes",
      },
      {
        field: "nombre",
        header: "Asesor",
      },
      {
        field: "createdAt",
        header: "Fecha",
      },
    ];
    this.columnsrep4 = [
      {
        field: "count",
        header: "Transferencias",
      },
      {
        field: "comentario",
        header: "Motivo",
      },
    ];

    this.columnsrep5 = [
      {
        field: "date",
        header: "Fecha",
      },
      {
        field: "hora",
        header: "Hora",
      },
      {
        field: "total",
        header: "Total",
      },
    ];

    this.columnsrep6 = [
      {
        field: "asesor_nombre",
        header: "Asesor",
      },
      {
        field: "tiempo_trans",
        header: "Horas contestadas",
      },
      {
        field: "de",
        header: "Empezó",
      },
      {
        field: "hasta",
        header: "Terminó",
      },
      {
        field: "fecha",
        header: "Fecha",
      },
    ];

    this.columnsrep7 = [
      {
        field: "fecha",
        header: "Fecha",
      },
      {
        field: "hora",
        header: "Hora",
      },
      {
        field: "totalPacientesNoAtendidos",
        header: "No se antendieron",
      },
      {
        field: "totalBot",
        header: "Asistente Virtual",
      },
      {
        field: "totalAtendidos",
        header: "En asesor",
      },
      {
        field: "totalPacientes",
        header: "TOTAL",
      },
    ];

    this.columnsrep8 = [
      {
        field: "fecha",
        header: "Fecha",
      },
      {
        field: "hora",
        header: "Hora",
      },
      {
        field: "total",
        header: "Clientes no atendidos",
      },
    ];

    this.columnsrep9 = [
      {
        field: "nombre",
        header: "Asesor",
      },
      {
        field: "queja",
        header: "Quejas",
      },
      {
        field: "atendido",
        header: "Atendido",
      },
      {
        field: "credito",
        header: "Credito",
      },
      {
        field: "ventas",
        header: "Ventas",
      },
      {
        field: "total",
        header: "Total",
      },
    ];
    this.columnsrep10 = [
      {
        field: "asesor",
        header: "Asesor",
      },
      {
        field: "total",
        header: "Pacientes atendidos",
      },
      {
        field: "fecha",
        header: "Fecha",
      },
    ];
    this.columnsrep11 = [
      {
        field: "pacientes",
        header: "Pacientes",
      },
      {
        field: "fecha",
        header: "Fecha",
      },
    ];

    this.columnsrep12 = [
      {
        field: "total",
        header: "Pacientes",
      },
      {
        field: "fecha",
        header: "Fecha",
      },
    ];

    this.columnsrep13 = [
      {
        field: "atendidos",
        header: "Pacientes",
      },
      {
        field: "fecha",
        header: "Fecha",
      },
    ];
  },
  data() {
    return {
      status: false,
      fecha_inicio: null,
      ochenta: 80,
      datos: null,
      tabla: [
        {
          field: "fecha",
          header: "Fecha",
        },
        {
          field: "hora",
          header: "Hora",
        },
        {
          field: "totalPacientesNoAtendidos",
          header: "No se antendieron",
        },
        {
          field: "totalBot",
          header: "Asistente Virtual",
        },
        {
          field: "totalAtendidos",
          header: "En asesor",
        },
        {
          field: "totalPacientes",
          header: "TOTAL",
        },
      ],
      reporte: "",
      brands: null,
      brand: null,
      filteredBrands: null,
      columnsrep1: null,
      columnsrep2: null,
      columnsrep3: null,
      columnsrep4: null,
      columnsrep5: null,
      columnsrep6: null,
      columnsrep7: null,
      columnsrep9: null,
      columnsrep10: null,
      cols: "",
      datacol: "",

      vsrep1: false,
      vsrep2: false,
      vsrep3: false,
      vsrep4: false,
      vsrep5: false,
      vsrep6: false,
      vsrep7: false,
      vsrep8: false,
      vsrep9: false,
      vsrep10: false,
      cvshow2: false,
      porHora: false,

      feliz: {
        asesor: {
          calificaciones: [
            {
              calificacion: 5,
            },
          ],
        },
        bot: {
          calificaciones: [
            {
              calificacion: 5,
            },
          ],
        },
      },
      felizometroAsesor: null,
      resultados: null,
      horaspico: null,
      atendiendo: null,
      hrAsesor: null,
      monitorHr: null,
      flujos: null,
      noAtendidos: null,
      basicData: "",
      fecha_final: null,
      options: {
        responsive: true,
        hoverMode: "index",
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.status = true;
    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    var today = year + "-" + month + "-" + day;
    this.fecha_final = today;
    this.fecha_inicio = `${year}-${month}-${"01"}`;
    this.brands = [
      {
        id: 1,
        reporte: "Total de clientes atendidos por general",
        descripcion:
          "Cuántos clientes fueron atendidos cada día del periodo seleccionado. La cifra total es general, contempla a clientes de bot y asesor.",
      },
      {
        id: 2,
        reporte: "Total de clientes atendidos por asesores",
        descripcion:
          "Cuántos clientes fueron atendidos por asesores cada día del periodo seleccionado.",
      },
      {
        id: 3,
        reporte: "Total de clientes atendidos por c/asesor",
        descripcion:
          "Cuántos clientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
      },
      {
        id: 4,
        reporte: "Traspasos por motivo",
        descripcion:
          "Total de traspasos por motivo dentro del periodo seleccionado.",
      },
      {
        id: 5,
        reporte: "Clientes atendidos por hora",
        descripcion:
          "Muestra la cantidad de clientes por horas del periodo seleccionado.",
      },
      {
        id: 6,
        reporte: "Registro de asesor por horas atendidas y día",
        descripcion:
          "Total de horas atendidas por cada asesor, con hora de inicio y hora de finalización, dividido por días del periodo seleccionado.",
      },
      {
        id: 7,
        reporte: "Atención diaría por hora",
        descripcion:
          "Cuántos pacientes fueron atendidos cada hora por bot y asesor, así como el total de pacientes atendidos cada hora. También marca cuántos pacientes no fueron atendidos esa hora.",
      },
      {
        id: 8,
        reporte: "Clientes no atendidos",
        descripcion: "Numero de clientes que no se atendieron por hora",
      },
      {
        id: 9,
        reporte: "Etiquetas por asesor",
        descripcion:
          "Total de etiquetas que asignó cada asesor durante el periodo seleccionado.",
      },
      {
        id: 10,
        reporte: "Facebook | Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado."
      },
      {
        id: 11,
        reporte: "Facebook | Atendidos Unicos",
        descripcion: ""
      },
      {
        id: 12,
        reporte: "Facebook | Solicitaron asesor",
        descripcion: ""
      },
      {
        id: 13,
        reporte: "Facebook | Pacientes",
        descripcion: ""
      },
    ];
  },
  methods: {
    searchBrand(event) {
      setTimeout(() => {
        let results;

        if (event.query.length === 0) {
          results = [...this.brands];
        } else {
          results = this.brands.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }

        this.filteredBrands = results;
      }, 250);
    },
    consultarReporte() {
      console.log(this.reporte.id);
      console.log("consultar reporte");
      this.status = false;
      if (
        this.fecha_inicio == "" ||
        this.fecha_final == "" ||
        this.reporte.id <= 0
      ) {
        alert("Los datos ingresados no son correctos");
        this.status = true;
        } else if (this.vsrep1 || this.vsrep2 || this.vsrep3 || this.vsrep4 ) {
        this.cols = "";
        if (this.vsrep1) {
          this.tabla = this.columnsrep1;
        } else if (this.vsrep2) {
          this.tabla = this.columnsrep2;
        } else if (this.vsrep3) {
          this.tabla = this.columnsrep3;
        } else if (this.vsrep4) {
          this.tabla = this.columnsrep4;
        }
        reportesServices
          .reporte(this.fecha_inicio, this.fecha_final, this.reporte.id)
          .then((datos) => {
            console.log(datos);
            var moment = require("moment");
            moment.locale("es");
            this.cols = datos;
            this.datos = this.cols;

            this.cvshow2 = false;
            if (this.reporte.id == 1) {
              datos.datos = datos.datos.map((x) => {
                x.createdAt = moment(x.createdAt).format("YYYY MMMM DD, dddd");
                return x;
              });
              this.cols = datos.datos;
              this.datos = this.cols;
              this.cvshow2 = true;
              this.basicData = {
                labels: datos.label,
                datasets: [
                  {
                    label: "Clientes atendidos por asistente virtual",
                    data: datos.data,
                    fill: false,
                    backgroundColor: "#2f4860",
                    borderColor: "#2f4860",
                  },
                ],
              };
            } else {
              datos = datos.map((x) => {
                x.createdAt = moment(x.createdAt).format("YYYY MMMM DD, dddd");
                return x;
              });
            }
            this.status = true;
          })
          .catch((e) => {
            alert(
              "Reintentar en 1 min. " +
                e +
                ".\r\nAl 3er intento favor de reportarlo con un administrador del sistema"
            );
            this.status = true;
          });
      } else {
        if (this.vsrep5) {
          //Horas Pico
          this.tabla = this.columnsrep5;
          reportesServices
            .horaPicoReporte(this.fecha_inicio, this.fecha_final)
            .then((datos) => {
              this.horaspico = datos;
              this.datos = this.horaspico;
              if (
                this.fecha_final.substr(8, 2) -
                  this.fecha_inicio.substr(8, 2) ==
                0
              ) {
                this.porHora = true;
                let totales = [];
                //let completo=[]
                //let fechas=[]
                let horas = [];
                //fechas.push(this.horaspico[x].date)
                for (let x = 0; x < this.horaspico.length; x++) {
                  totales.push(this.horaspico[x].total);

                  if (this.horaspico[x].hora == 0) {
                    horas.push("12am");
                  } else if (this.horaspico[x].hora < 12) {
                    horas.push(this.horaspico[x].hora + "am");
                  } else if (this.horaspico[x].hora == 12) {
                    horas.push("12pm");
                  } else if (this.horaspico[x].hora > 12) {
                    horas.push(this.horaspico[x].hora - 12 + "pm");
                  }
                }

                this.basicData = {
                  labels: horas,
                  datasets: [
                    {
                      label: this.horaspico[0].date,
                      data: totales,
                      fill: false,
                      backgroundColor: "#2f4860",
                      borderColor: "#2f4860",
                    },
                  ],
                };
              } else {
                this.porHora = false;
              }
              this.status = true;
            })
            .catch((e) => {
              alert(
                "Reintentar en 1 min. " +
                  e +
                  ".\r\nAl 3er intento favor de reportarlo con un administrador del sistema"
              );
              this.status = true;
            });
        } else if (this.vsrep6) {
          //Hora Asesor
          this.tabla = this.columnsrep6;
          reportesServices
            .horasAsesor(this.fecha_inicio, this.fecha_final)
            .then((datos) => {
              var moment = require("moment");
              moment.locale("es");
              datos = datos.map((x) => {
                x.fecha = moment(x.fecha).utc().format("YYYY MMMM DD, dddd");
                x.tiempo_trans = x.tiempo_trans.substr(0, 8) + " hrs";
                x.de = moment(x.min).utc().format("hh:mm a");
                x.hasta = moment(x.max).utc().format("hh:mm a");
                return x;
              });
              this.hrAsesor = datos;
              this.datos = this.hrAsesor;
              this.status = true;
            })
            .catch((e) => {
              alert(
                "Reintentar en 1 min. " +
                  e +
                  ".\r\nAl 3er intento favor de reportarlo con un administrador del sistema"
              );
              this.status = true;
            });
        } else if (this.vsrep7) {
          //Atencion diaria
          this.tabla = this.columnsrep7;
          reportesServices
            .monitorHR(this.fecha_inicio, this.fecha_final)
            .then((datos) => {
              var moment = require("moment");
              moment.locale("es");
              datos = datos.map((x) => {
                x.fecha = moment(x.date).utc().format("YYYY MMMM DD, dddd");
                if (x.hora == 0) {
                  x.hora = "12am";
                } else if (x.hora < 12) {
                  x.hora = x.hora + "am";
                } else if (x.hora == 12) {
                  x.hora = "12pm";
                } else if (x.hora > 12) {
                  x.hora = x.hora - 12 + "pm";
                }
                return x;
              });
              this.monitorHr = datos;
              this.datos = this.monitorHr;
              this.status = true;
            })
            .catch((e) => {
              alert(
                "Reintentar en 1 min. " +
                  e +
                  ".\r\nAl 3er intento favor de reportarlo con un administrador del sistema"
              );
              this.status = true;
            });
        } else if (this.vsrep8) {
          //Pacientes no atendidos
          this.tabla = this.columnsrep8;
          reportesServices
            .pacientesNoAtendidos(this.fecha_inicio, this.fecha_final)
            .then((datos) => {
              var moment = require("moment");
              moment.locale("es");
              datos = datos.map((x) => {
                x.fecha = moment(x.timezone).utc().format("YYYY MMMM DD, dddd");
                return x;
              });
              this.noAtendidos = datos;
              this.datos = this.noAtendidos;
              this.status = true;
            })
            .catch((e) => {
              alert(
                "Reintentar en 1 min. " +
                  e +
                  ".\r\nAl 3er intento favor de reportarlo con un administrador del sistema"
              );
              this.status = true;
            });
        } else if (this.vsrep9) {
          if (this.reporte.id == 9) {
            //etiquetas
            this.tabla = this.columnsrep9;
            reportesServices
              .etiqueta(this.fecha_inicio, this.fecha_final)
              .then((datos) => {
                var moment = require("moment");
                moment.locale("es");
                datos = datos.map((x) => {
                  x.fecha = moment(x.createdAt).format("YYYY MMMM DD, dddd");

                  return x;
                });
                this.datos = datos;
                this.status = true;
              })
              .catch((e) => {
                alert(
                  "Reintentar en 1 min. " +
                    e +
                    ".\r\nAl 3er intento favor de reportarlo con un administrador del sistema"
                );
                this.status = true;
              });
          }
        } 

        else if (this.vsrep10) {
          //facebook atendidos
          this.tabla=this.columnsrep10
          reportesServices.facebookAtendidos(this.fecha_inicio, this.fecha_final).then((datos) => {
              console.log(datos)
              var moment = require("moment")
              moment.locale("es")
              datos = datos.map((x) => {
                x.fecha = moment(x.createdAt).format("YYYY MMMM DD, dddd")
                
                return x
              })
              this.datos=datos
              this.status=true
            }).catch(e=>{
              alert('Reintentar en 1 min. '+ e + '.\r\nAl 3er intento favor de reportarlo con un administrador del sistema')
              this.$gtag.event('errores',{
                        'event_category': 'reportes',
                        'event_label': e,
                        'value': 500
                    })
              this.status=true
              })
        }else if (this.vsrep11) {
          //facebook atendidos unicos
          this.tabla=this.columnsrep11
          reportesServices.facebookAtendidosU(this.fecha_inicio, this.fecha_final).then((datos) => {
              var moment = require("moment")
              moment.locale("es")
              datos = datos.map((x) => {
                x.fecha = moment(x.createdAt).format("YYYY MMMM DD, dddd")
                
                return x
              })
              this.datos=datos
              this.status=true
            }).catch(e=>{
              alert('Reintentar en 1 min. '+ e + '.\r\nAl 3er intento favor de reportarlo con un administrador del sistema')
              this.$gtag.event('errores',{
                        'event_category': 'reportes',
                        'event_label': e,
                        'value': 500
                    })
              this.status=true
              })
        }else if (this.vsrep12) {
          //facebook solicitud asesor
          this.tabla=this.columnsrep12
          reportesServices.facebookSolicitudAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {
              var moment = require("moment")
              moment.locale("es")
              datos = datos.map((x) => {
                x.fecha = moment(x.createdAt).format("YYYY MMMM DD, dddd")
                
                return x
              })
              this.datos=datos
              this.status=true
            }).catch(e=>{
              alert('Reintentar en 1 min. '+ e + '.\r\nAl 3er intento favor de reportarlo con un administrador del sistema')
              this.$gtag.event('errores',{
                        'event_category': 'reportes',
                        'event_label': e,
                        'value': 500
                    })
              this.status=true
              })
        }else if (this.vsrep13) {
          //facebook pacientes totales
          this.tabla=this.columnsrep13
          reportesServices.facebookPacientes(this.fecha_inicio, this.fecha_final).then((datos) => {
            var moment = require("moment")
            moment.locale("es")
            datos = datos.map((x) => {
              x.fecha = moment(x.createdAt).format("YYYY MMMM DD, dddd")
              
              return x
            })
            this.datos=datos
            this.status=true
            }).catch(e=>{
              alert('Reintentar en 1 min. '+ e + '.\r\nAl 3er intento favor de reportarlo con un administrador del sistema')
              this.$gtag.event('errores',{
                        'event_category': 'reportes',
                        'event_label': e,
                        'value': 500
                    })
              this.status=true
              })
        }
      }
    },
    todoFalso() {
      this.cvshow2 = false
      this.vsrep1 = false
      this.vsrep2 = false
      this.vsrep3 = false
      this.vsrep4 = false
      this.vsrep5 = false
      this.vsrep6 = false
      this.vsrep7 = false
      this.vsrep8 = false
      this.vsrep9 = false
      this.vsrep10 = false
      this.vsrep11 = false
      this.vsrep12 = false
      this.vsrep13 = false
      this.datos=null
      
      this.porHora = false
    },
    signalChange: function () {
      this.cols = "";
      switch (this.reporte.id) {
        case 1:
          this.todoFalso();
          this.vsrep1 = true;
          break;
        case 2:
          this.todoFalso();
          this.vsrep2 = true;
          break;
        case 3:
          this.todoFalso();
          this.vsrep3 = true;
          break;
        case 4:
          this.todoFalso();
          this.vsrep4 = true;
          break;
        case 5:
          this.todoFalso();
          this.vsrep5 = true;
          break;
        case 6:
          this.todoFalso();
          this.vsrep6 = true;
          break;
        case 7:
          this.todoFalso();
          this.vsrep7 = true;
          break;
        case 8:
          this.todoFalso();
          this.vsrep8 = true;
          break;
        case 9:
          this.todoFalso();
          this.vsrep9 = true;
          break;
        case 10:
          this.todoFalso();
          this.vsrep10 = true;
          break;
        case 11:
          this.todoFalso();
          this.vsrep11 = true;
          break;
        case 12:
          this.todoFalso();
          this.vsrep12 = true;
          break;
        case 13:
          this.todoFalso();
          this.vsrep13 = true;
          break;
      }
    },
  },
};
</script>
