import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import SocketIo from 'socket.io-client'
//import VueSocketIO from 'vue-socket.io'
import('@/assets/css/bootstrap.min.css')
import('primevue/resources/themes/md-light-indigo/theme.css')
import('primevue/resources/primevue.min.css')
import('primeicons/primeicons.css')

import('@/assets/css/style.min.css?v=20220313')
import PrimeVue from 'primevue/config'
import EventBus from '@/plugins/event-bus'
import jQuery from 'jquery'
import Popper from 'popper.js'
global.jQuery = jQuery;
global.Popper = Popper;
require('bootstrap')
global.$ = jQuery
import ToastService from 'primevue/toastservice';

import VueResource from 'vue-resource'
// import VueGtag from "vue-gtag";

// Vue.use(VueGtag, {
//   config: { id: "UA-153309479-1" },
//   appName: 'Plataforma Inbox',
//   pageTrackerScreenviewEnabled: true
// });
Vue.use(PrimeVue, ToastService)
Vue.use(VueResource)
Vue.use(EventBus, ) //VueSocketIO, SocketInstance
Vue.config.productionTip = false
Vue.prototype.$abrircerrar = false
new Vue({
  router,
  render: h => h(App),
  
}).$mount('#app')