<template>
  <div class="container-fluid align-self-center login">
    <div class="loginLeftPT1"></div>
    <div class="loginLeftPT2"></div>
    <div class="loginLogo"></div>
    <div class="contenedorLogin">
      <form id="formulario" @submit.prevent="login">
        <div class="contUsuario">
          <input
            type="text"
            v-model="user"
            class="inputUser required imgIcoUser"
            placeholder="Correo electrónico"
            required
          />
        </div>
        <div class="contPass">
          <input
            :type="verPass ? 'text' : 'password'"
            v-model="pass"
            class="inputPass required imgIcoContra"
            placeholder="Contraseña"
            required
          />
          <a @click="verPass = !verPass" class="pointer" v-if="!verPass">
            <img src="../assets/images/password.svg" alt="" />
          </a>
          <a @click="verPass = !verPass" class="pointer" v-if="verPass">
            <img src="../assets/images/ocultar.svg" alt="" />
          </a>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cbox_recordar" v-model="cbox_recordar">
            <label class="form-check-label" for="cbox_recordar">
            Recordarme
            </label>
        </div>
        <div :class="this.alert ? 'loginError' : 'loginAlert'" role="alert">
          {{ error }}
          <button @click="cerrar" type="button" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <span class="d-none text-danger">*Usuario o contraseña incorrecta</span>
        <input
          type="submit"
          id="enviar"
          value="Iniciar sesión"
          class="btn btn-primary btnLogin"
        />
      </form>
    </div>
    <div class="lineaAmarrilla"></div>
  </div>
</template>

<script>
import loginService from "../services/login";
// import {
//     library
// } from '@fortawesome/fontawesome-svg-core'
// import {
//     faTimesCircle,
//     faUser,
//     faLock
// } from '@fortawesome/free-solid-svg-icons'
// import {
//     FontAwesomeIcon
// } from '@fortawesome/vue-fontawesome'
// library.add(faTimesCircle,faUser, faLock)

export default {
  name: "LoginComponent",
  data() {
    return {
      cbox_recordar:false,
      pass: "",
      diaEspecial: false,
      hoy: new Date(),
      error: "",
      alert: false,
      status: true,
      passEncriptada: null,
      ultimaVez: JSON.parse(localStorage.getItem("sesionAnterior")),
      user: '',
      verPass: false
    };
  },
  components: {
    // 'font-awesome-icon': FontAwesomeIcon
  },
  created() {
    this.dia();
    this.user=this.ultimaVez.email || ""
    
    if(this.user != "")
      this.cbox_recordar=true
  },
  methods: {
    dia() {
      if ("32" == this.hoy.toString().substr(8, 2)) {
        this.diaEspecial = true;
      }
    },
    cerrar() {
      this.alert = false;
    },
    login() {
      console.log(this.user)
      this.status = false;

      if (this.ultimaVez != null && this.user != this.ultimaVez.email) {
        this.eliminarLocal()
      }

      loginService
        .login(this.user.toLowerCase(), this.pass)
        .then((res) => {
          console.log(res);
          const datosUser = {
            id: res.user.id,
            nombre: res.user.nombre,
            email: res.user.email,
            rol_id: res.user.rol_id,
            estatus: res.user.estatus,
            oculto: res.user.respuestas == 1 ? false : true,
            lastconnection: res.user.lastconnection,
            createdAt: res.user.createdAt,
            updatedAt: res.user.updatedAt,
            token: res.accesToken,
            grupo_id: res.user.grupo_id,
          };
          sessionStorage.clear();
          sessionStorage.setItem("sesion", JSON.stringify(datosUser));
          sessionStorage.setItem("asesorId", res.user.id);
          if(this.cbox_recordar)
            this.guardarEnLocal(res.user.id, res.user.nombre);
          else
            this.eliminarLocal()
          sessionStorage.setItem("token", res.accesToken);
          if (res.user.rol_id == 1 || res.user.rol_id == 2)
            window.location.href = "/dashboard";
          else if (res.user.rol_id == 4) window.location.href = "/dashboard";
          else window.location.href = "/chat";
        })
        .catch((err) => {
          this.alert = true;
          this.status = true;
          if (err == "Error: Network Error") {
            this.error = "No hay conexión con servidor";
          } else if (err == "Error: Request failed with status code 404") {
            this.error = "Usuario o contraseña incorrecto";
          } else if (err == "Error: Request failed with status code 500") {
            this.error = "Reintentar en 1 minuto, Error 500";
          } else {
            this.error = err.data.msj;
          }
        });
    },
    guardarEnLocal(id, nombre) {
      let info = {
        email: this.user,
        id: id,
        nombre: nombre,
      };
      localStorage.setItem("sesionAnterior", JSON.stringify(info));
    },
    eliminarLocal() {
      localStorage.clear();
      this.ultimaVez = null;
    },
  },
};
</script>
