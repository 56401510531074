<template>
  <div class="container-fluid px-0 mt-1">
    <div class="row mb-2">
      <div class="col-5 switch-network d-flex flex-row justify-content-center">
        <div class="btn-network rounded-circle bg-whatsapp" >
          <i class="icon-whatsapp opt-wa"><font-awesome-icon :icon="['fab', 'whatsapp']" size="1x"/></i>
        </div>            
        
      </div>
      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="h4" icon="comment"/>
      </div>

      <div class="col-5 switch-network d-flex flex-row justify-content-center">
        <div class="btn-network rounded-circle bg-messenger">
            <i class="icon-messenger-new opt-fb"><font-awesome-icon :icon="['fab', 'facebook-messenger']" size="1x"/></i>
        </div>
      </div>
    </div>
    <div class="row infoMonitor" v-tooltip.top="'Atención total'">
       <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
        <p class="PxGeneral pb-0">{{ datos && datos.whatsapp && datos.whatsapp.pacientes ? datos.whatsapp.pacientes[0].count : '...' }}</p>
        <!-- <p class="PxGeneral pb-0">{{datos.whatsapp.pacientes[0].count}}<br> <small class="PxColaN"><img class="bandera mr-1" src="../../../assets/images/ni.svg" height="13px" alt="Bandera de Nicaragua" /><b>{{datos.nicaragua.pacientes}}</b></small></p> -->
      </div>

      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="dPaciente h4" icon="user-injured"/>
      </div>

      <div class="col-5 d-flex flex-row justify-content-center align-items-center">
        <p class="PxGeneral">{{ (datos && datos.facebook && datos.facebook.pacientes) ? datos.facebook.pacientes: '...'}}</p>
      </div>

    </div>

    <div class="row infoMonitor" v-tooltip.top="'Clientes atendidos por el asistente virtual'">
      <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
         <p class="PxBot pb-0" v-if="!hover">{{datos && datos.whatsapp && datos.whatsapp.pacientes ? datos.whatsapp.pacientes[0].count-datos.whatsapp.atendidos[0].count : '...'}}
           <p class="PxBot" v-else>{{((datos.whatsapp.pacientes[0].count-datos.whatsapp.atendidos[0].count)*100/(datos.whatsapp.pacientes[0].count)).toFixed(1)+'%'}}</p>
      </div>

      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="dBot h4" icon="robot"/>
      </div>

      <div class="col-5 d-flex flex-row justify-content-center align-items-center">
        <p class="PxBot" v-if="!hover">{{datos.facebook.pacientes-datos.facebook.atendidos}}</p>
        <p class="PxBot" v-else>{{((datos.facebook.pacientes-datos.facebook.atendidos)*100/(datos.facebook.pacientes)).toFixed(1)+'%'}}</p>
      </div>
    </div>
    <div class="row infoMonitor" v-tooltip.top="'Clientes atendidos por un asesor'">
      <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
        <p class="PxAsesor pb-0" v-if="!hover">{{datos && datos.whatsapp && datos.whatsapp.atendidos ? datos.whatsapp.atendidos[0].count : '...'}} </p>
        <p class="PxAsesor" v-else>{{((datos.whatsapp.atendidos[0].count)*100/(datos.whatsapp.pacientes[0].count)).toFixed(1)+'%'}}</p>
      </div> 

      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="dAsesor h4" icon="user"/>
      </div>   

      <div class="col-5 d-flex flex-row justify-content-center align-items-center">
        <p class="PxAsesor" v-if="!hover">{{datos.facebook.atendidos}}</p>
        <p class="PxAsesor" v-else>{{((datos.facebook.atendidos)*100/(datos.facebook.pacientes)).toFixed(1)+'%'}}</p>
      </div>

    </div>
    <div class="row infoMonitor" v-tooltip.top="'Tiempo de espera promedio'">
      <div class="col-5 d-flex flex-row justify-content-center">
        <p class="PxAtencion" :class="{'text-danger': horaFB > 2, 'text-warning':horaFB > 1}">{{horaFB}}:{{minutoFB}} hrs</p>
      </div>

      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="h4" icon="user-clock"/>
      </div>
      <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
        <p class="PxAtencion" :class="{'text-danger': horaWA > 2, 'text-warning':horaWA > 1}">{{horaWA}}:{{minutoWA}} hrs</p>
      </div>
    </div>
    <div class="row infoMonitor" v-tooltip.top="'Tiempo de espera máximo'">
      <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
        <p class="PxAtencionCola" >{{ (colaAntigua &&  colaAntigua.whatsapp && colaAntigua.whatsapp.tiempo) ? colaAntigua.whatsapp.tiempo.substring(1,3)=='day'?'+24':colaAntigua.whatsapp.tiempo.substring(1,9) + 'hrs' : '...' }} </p>
      </div>
      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="h4" icon="clock"/>
      </div>
      <div class="col-5 d-flex flex-row justify-content-center">
        <p class="PxAtencionCola" >{{(colaAntigua && colaAntigua.facebook && colaAntigua.facebook.tiempo) ? colaAntigua.facebook.tiempo.substring(1,3)=='day'?'+24':colaAntigua.facebook.tiempo.substring(1,9) + 'hrs' : '...' }} </p>
      </div>
    </div>
    <div class="row infoMonitor" v-tooltip.top="'Sesiones perdidas'">
      <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
        <p class="PxSinSesion" :class="{'text-danger': (sesiones && sesiones.whatsapp && sesiones.whatsapp.sesiones) ? parseInt(sesiones.whatsapp.sesiones) > 20 : false, 'text-warning':(sesiones && sesiones.whatsapp && sesiones.whatsapp.sesiones) ?parseInt(sesiones.whatsapp.sesiones) > 5 : false }">{{(sesiones && sesiones.whatsapp && sesiones.whatsapp.sesiones) ? sesiones.whatsapp.sesiones : '...' }}</p>
      </div>
      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="h4" icon="user-times"/>
      </div>
      <div class="col-5 d-flex flex-row justify-content-center">
        <p class="PxSinSesion" >{{sesiones.facebook.sesiones}}</p>
      </div>
    </div>
    
    <div class="row infoMonitor" v-tooltip.top="'Clientes en espera de atención'">
      <div class="col-5 d-flex flex-row justify-content-center pr-4 ">
        <p>
          <b class="PxCola" :class="{'text-dark':cola.whatsapp.cola<=0,'text-success':cola.whatsapp.cola<=20,'text-orange':cola.whatsapp.cola>=21, 'text-danger': cola.whatsapp.cola>=60}" >{{cola.whatsapp.cola}}</b> <br>
        </p>
      </div>
      <div class="col-2 d-flex flex-row justify-content-center align-items-center">
        <font-awesome-icon class="dCola h4" icon="user-injured"/><font-awesome-icon class="dCola h5" icon="user-friends"/>
      </div>
      
      <div class="col-5 d-flex flex-row justify-content-center align-items-center">
        <p class="PxCola" :class="{'text-dark':cola.facebook.cola<=0,'text-success':cola.facebook.cola<=6, 'text-orange':cola.facebook.cola>=7, 'text-danger': cola.facebook.cola>=50}" >{{cola.facebook.cola}}</p>
      </div>
      
    </div>
  </div>
</template>
<script>
import Tooltip from 'primevue/tooltip'
import SocketIo from 'socket.io-client'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComment, faUserInjured, faRobot, faUser, faUserFriends, faUserTimes, faThLarge, faListUl, faUserClock, faClock } from '@fortawesome/free-solid-svg-icons'
library.add(faComment, faUserInjured, faRobot, faUser, faUserFriends, faUserTimes, faThLarge, faListUl, faUserClock, faClock)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    name: 'DatosXred',
    data() {
      return {
        datos: { "whatsapp": { "pacientes": [ { "count": "-1", "createdAt": "2020-08-20" } ], "atendidos": [ { "count": "-1", "createdAt": "2020-08-20" } ] }, "facebook": { "pacientes": [ { "count": "-1", "createdAt": "2020-08-20" } ], "atendidos": [ { "count": "-1", "createdAt": "2020-08-20" } ] } },
        cola: { "facebook": { "cola": -1 }, "whatsapp": { "cola": -1 } },
        //hola:null,
        hover: false,
        rol: JSON.parse(sessionStorage.getItem('sesion')).rol_id,
        minutoWA:'00',
        minutoFB:'00',
        horaWA:'00',
        horaFB:'00',
        sesiones:[{atendidos:0}],
        colaAntigua:null,
        nicaragua:-1
      }
    },
    components: {
        FontAwesomeIcon
    },
    directives: {
      'tooltip': Tooltip
    },
    created(){
      let data = {
        query: {
            "Tipo": "1"
        }
      }

      var socket = SocketIo.connect('https://valdez-socket.auplex.mx', data)
      socket.on('pacientesPorDiaRedes',  (payload) => {
        console.log('pacientesPorDiaRedes',payload)
        this.datos=payload
      })
      socket.on('pedirColaRedes',  (payload) => {
        console.log('pedirColaRedes',payload)
        this.cola=payload
      })
      socket.on('AntiguoCola',  (payload) => {
        console.log('AntiguoCola',payload)
        this.colaAntigua=payload
      })
      // socket.on('parametros',  (payload) => {
      //  this.hola=payload
      // })
      socket.on('HorasAtencionRedes',  (payload) => { 
        console.log('HorasAtencionRedes',payload)
        if(payload.facebook.tiempo.minutes<10){
          this.minutoFB=  payload.facebook.tiempo.minutes ? '0'+payload.facebook.tiempo.minutes :'00'
        }else if(payload.facebook.tiempo.minutes==0 || payload.facebook.tiempo.minutes===undefined){
          this.minutoFB='00'
        }else{
          this.minutoFB=payload.facebook.tiempo.minutes
        }
        if(payload.whatsapp.tiempo.minutes<10){
          this.minutoWA= payload.whatsapp.tiempo.minutes ? '0'+payload.whatsapp.tiempo.minutes :'00'
        }else if(payload.whatsapp.tiempo.minutes==0 || payload.whatsapp.tiempo.minutes===undefined){
          this.minutoWA='00'
        }else{
          this.minutoWA=payload.whatsapp.tiempo.minutes
        }
        if(payload.whatsapp.tiempo.hours==0 || payload.whatsapp.tiempo.hours===undefined){
          this.horaWA="0"
        }else{
          this.horaWA=payload.whatsapp.tiempo.hours
        }
        if(payload.facebook.tiempo.hours==0 || payload.facebook.tiempo.hours===undefined){
          this.horaFB="0"
        }else{
          this.horaFB=payload.facebook.tiempo.hours
        }
      })

      socket.on('SesionesP',  (payload) => {
      // console.log('SesionesP',payload)

        // console.log(payload)
        // if(payload.lentgh==0||payload[0]===undefined){
        //     this.sesiones[0].atendidos=0
        // }else{
          this.sesiones=payload
        // }
      }) 
    },
}
</script>